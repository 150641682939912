import React, { useContext, useEffect, useState } from "react";
import ErrorPopup from "components/popups/ErrorPopup";
import { useVideoHandlers } from "utility/utility_functions/videos";
import { SubmitCoachFormData } from "utility/constants/useContext";
import ReactQuill from "react-quill";
import { updateForm } from "state/SetCoachFormData";

function FormHeaderRight() {
  const Props = useContext(SubmitCoachFormData);
  const [placeHolder, setPlaceHolder] = useState(Props.formState.aboutYourSelf);
  const [wordCountAbout, setWordCountAbout] = useState(0);

  const {
    handleFileChange,
    videoFileURL,
    errorHeader,
    errorMessage,
    errorPopup,
    setErrorPopup,
  } = useVideoHandlers();

  // useEffect(() => {
  //   setPlaceHolder(Props.formState.aboutYourSelf);
  // }, []);

  // word counter
  const countWords = (text) => {
    return text.length;
  };

  function handleAbout(e) {
    setPlaceHolder(e);

    Props.dispatch(updateForm({ aboutYourSelf: e }));
    setWordCountAbout(countWords(e));
  }

  return (
    <>
      {errorPopup && (
        <>
          <div className="blur"></div>
          <ErrorPopup
            message={{
              heading: errorHeader,
              message: errorMessage,
            }}
            setErrorPopup={setErrorPopup}
          />
        </>
      )}
      <div className="w-1/2 pt-0 pb-6 px-8 form_header_right mt-[4rem] ">
        <div className="about_div">
          <h4 className="text-[var(--blue)] font-thin text-[1.3rem]">
            Write something about yourself so that your athletes can understand
            you well.{" "}
          </h4>
          <div className="yourself">
            <label htmlFor="input" className="text z-10">
              About Yourself:
              <sup className="text-[#d0312d]"> *</sup>
            </label>
            <ReactQuill
              theme="snow"
              value={placeHolder}
              onChange={handleAbout}
            />
            {wordCountAbout > 1000 && (
              <label className="name_counter text-[var(--red)]">
                ({wordCountAbout}/1000)
              </label>
            )}
            {/* <textarea
              id="w3review"
              name="w3review"
              rows="10"
              class="input"
              onChange={(e) => Props.setAoutYourSelf(e.target.value)}
              onClick={() => setPlaceHolder("")}
            ></textarea> */}
          </div>
        </div>

        <div className="video_div">
          <h4 className="text-[var(--blue)] font-thin text-[1.3rem]">
            You can upload a 1min video talking about yourself.
          </h4>
          <video id="video" controls src={Props.formState.getVideo}></video>
          <input
            id="file-input"
            className="mt-4"
            type="file"
            accept="video/*"
            onChange={(event) => handleFileChange(event, Props.setVideoPath)}
          />
        </div>
      </div>
    </>
  );
}

export default FormHeaderRight;
