import React, { useContext, useState, useEffect } from "react";
import Logo from "components/logo/Logo";
import Bell from "assets/icons/notifications.svg";
import star from "assets/icons/Star.png";
import ActionPopup from "components/popups/ActionPopup";
import { DeleteCoachForm } from "api/CoachFormController";
import PhoneNotification from "components/profileBox/PhoneNotification";
import { getlocalStorageData } from "hooks/basic";
import { AppProps } from "utility/constants/useContext";
import { API_URL_CONSTANTS } from "utility/constants/apiUrlConstants";
import { getApis } from "hooks/api";
function PhoneNavBar() {
  const localStorageData = getlocalStorageData();
  const [bell, setBell] = useState(false);
  const [deletePopup, setDeletePopup] = useState(false);
  const [load, setLoad] = useState(false);
  const AppData = useContext(AppProps);
  const [profileImgSrc, setProfileImgSrc] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const resProfilePhoto = await getApis(
          API_URL_CONSTANTS.GET_PROFILE_IMG
        );

        if (resProfilePhoto.data.status_code === 2) {
          setProfileImgSrc("");
        } else {
          setProfileImgSrc(resProfilePhoto.data.message);
        }
      } catch (error) {
        return error;
      }
    };
    fetchData();
  }, [AppData.isSignedIn]);

  function handleClick() {
    AppData.setOpenP(!AppData.isOpenP);
  }

  async function handleRemove() {
    if (load === true) {
      return;
    }
    setLoad(true);
    const res = await DeleteCoachForm();
    if (res) {
      setLoad(true);
      setDeletePopup(false);
      setBell(false);
      window.location.href = "/";
    } else {
      setLoad(false);
    }
  }
  return (
    <>
      {/* DELETE POPUP */}
      {deletePopup && (
        <>
          <div className="delete_blur"></div>
          <ActionPopup
            message={{
              heading: "Delete Form",
              message: `Do you really want to delete this form? This
          process cannot be undone`,
            }}
            setDeletePopup={setDeletePopup}
            handleRemove={handleRemove}
            load={load}
          />
        </>
      )}

      {/* Bell icon */}
      <PhoneNotification
        bell={bell}
        setBell={setBell}
        setDeletePopup={setDeletePopup}
      />
      <div className=" flex justify-between items-center fixed top-0 w-full h-[4rem] bg-[var(--blue)] py-4 px-2 z-20">
        <div className="phone_logo">
          <Logo />
        </div>
        {/* // user initial */}
        <div className="menu_button">
          {AppData.coachSignUpStatus === "Pending verfication" ||
          AppData.coachSignUpStatus === "Need Action" ? (
            <div className="bell_div">
              <div className="red_circle"></div>
              <img onClick={() => setBell(!bell)} src={Bell} alt="" />
            </div>
          ) : (
            <></>
          )}

          {!AppData.isSignedIn && (
            <svg
              onClick={handleClick}
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              fill="currentColor"
              className="bi bi-list"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"
              />
            </svg>
          )}

          {AppData.isSignedIn && (
            // <div className="phone_Profile_logo" onClick={handleClick}>
            //   <span>{AppData.userInitial}</span>
            //   {localStorageData.role === "Coach" && <img src={star} alt="" />}
            // </div>
            <>
              {profileImgSrc ? (
                <>
                  <div className="Profile_logo mr-0" onClick={handleClick}>
                    <img
                      src={`${API_URL_CONSTANTS.DOMAIN_NAME}${profileImgSrc}`}
                      alt=""
                    />
                  </div>

                  {localStorageData.role === "Coach" && (
                    <img className="coach_logo" src={star} alt="" />
                  )}
                </>
              ) : (
                <>
                  <span className="Profile_logo mr-0" onClick={handleClick}>
                    {AppData.userInitial}
                  </span>
                  {localStorageData.role === "Coach" && (
                    <img className="coach_logo" src={star} alt="" />
                  )}
                </>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default PhoneNavBar;
