import React, { useState } from "react";
import { HelmetProvider } from "react-helmet-async";
import Nav from "components/navbar/Nav";
import PhoneNavBar from "components/navbar/PhoneNavBar";
import PopUpLogin from "components/signin/PopUpLogin";
import SEO from "hooks/Seo";
import PopUpLoginP from "components/signin/PhonePopUpLogin";
import SignUpPopUp from "components/signup/SignUpPopUp";
import ViewCoachCard from "./viewCoachComponents/ViewCoachCard";
import ViewCoachCardPhone from "./viewCoachComponents/ViewCoachCardPhone";

function ViewCoaches() {
  const [isSignUp, setSignUp] = useState(false);

  // FORGET PASSWORD
  const [isForgotPassword, setForgotPassword] = useState(false);
  return (
    <HelmetProvider>
      <Nav setSignUp={setSignUp} isSignUp={isSignUp} />
      <PhoneNavBar />
      <PopUpLogin isSignUp={isSignUp} setSignUp={setSignUp} />
      <SignUpPopUp setSignUp={setSignUp} isSignUp={isSignUp} />
      <PopUpLoginP
        isForgotPassword={isForgotPassword}
        setForgotPassword={setForgotPassword}
        isSignUp={isSignUp}
        setSignUp={setSignUp}
      />
      <SEO
        title="Search for Coach | Tackofit"
        descriptionName="Athlete"
        description="Get expert help every step of the way. Connect with an accredited coach tailored to your needs or explore our coach directory."
        canonicalUrl="https://www.trackofit.com/become-a-coach"
      />
      <div className="bg-[var(--light-grey)] min-h-screen">
        <div className="width_70_div bg-[#f6f6f6] min-h-screen">
          <h1 className="text-[var(--blue)] text-[2.5rem] text-center font-normal pt-[1rem]">
            Look For Best Coaches
          </h1>

          <ViewCoachCard />
          <ViewCoachCardPhone />
        </div>
      </div>
    </HelmetProvider>
  );
}

export default ViewCoaches;
