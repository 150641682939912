import React, { useEffect, useState } from "react";
import CoverPhoto from "components/coverPhoto/CoverPhoto.jsx";
import ProfileBox from "./accountPageComponents/ProfileBox.jsx";
import Setting from "./accountPageComponents/Setting.jsx";
import ConnectedDevice from "./accountPageComponents/ConnectedDevice.jsx";
import MyCoaching from "./accountPageComponents/MyCoaching.jsx";
import MyEvent from "./accountPageComponents/MyEvent.jsx";
import ViewAccFooter from "./accountPageComponents/ViewAccFooter.jsx";
import Aboutus from "./accountPageComponents/AbooutUs.jsx";
import Certificate from "./accountPageComponents/Certificate.jsx";
import YouTrain from "./accountPageComponents/YouTrain.jsx";
import Nav from "components/navbar/Nav.jsx";
import PhoneNavBar from "components/navbar/PhoneNavBar.jsx";
import PopUpLoginP from "components/signin/PhonePopUpLogin.jsx";
import { getlocalStorageData } from "hooks/basic";
import { useSelector } from "react-redux";
import { HelmetProvider } from "react-helmet-async";
import SEO from "hooks/Seo.jsx";

function ViewAccount() {
  const data = useSelector((state) => state.viewAccount);
  const [certificates, setCertificates] = useState([]);

  // profile type coach or athlete
  const [profileType, setProfileType] = useState(null);

  const [editProfile, setEditProfile] = useState(false);
  const [editAout, setEditAbout] = useState(false);
  const [editSetting, setEditSetting] = useState(false);

  const localStorageData = getlocalStorageData();
  // scroll to top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const handleSubmit = () => {};
  // true = coach
  // API call for get data and check if coach acc or athlete acc
  useEffect(() => {
    if (localStorageData.role === "Coach") {
      setProfileType(true);
    } else if (localStorageData.role === "Athlete") {
      setProfileType(false);
    }
  }, []);

  return (
    <HelmetProvider>
      <div className="bg-[var(--light-grey)]">
        <SEO
          title="View Account | Tackofit"
          descriptionName="View account"
          description="Join Tackofit as a coach and help athletes achieve their full potential with personalized training plans and flexible coaching options."
          canonicalUrl="https://www.trackofit.com/#/viewaccount"
        />
        <Nav />
        <PhoneNavBar />
        <PopUpLoginP />

        {!data ? (
          <></>
        ) : (
          <div className="width_70_div">
            {/* <EditPhoto
            getData={getData}
            CoverImgSrc={CoverImgSrc}
            profileImgSrc={profileImgSrc}
            // setCoverPhotoPath={setCoverPhotoPath}
            // setProfilePhotoPath={setProfilePhotoPath}
          /> */}
            <CoverPhoto />
            <ProfileBox
              setEditProfile={setEditProfile}
              editAout={editAout}
              editSetting={editSetting}
            />
            <Aboutus
              setEditAbout={setEditAbout}
              editProfile={editProfile}
              editSetting={editSetting}
            />
            {profileType ? (
              <></>
            ) : (
              <Setting
                setEditSetting={setEditSetting}
                editProfile={editProfile}
                editAout={editAout}
              />
            )}

            {profileType ? (
              <Certificate
                certificates={certificates}
                setCertificates={setCertificates}
                handleSubmit={handleSubmit}
              />
            ) : (
              <></>
            )}

            {profileType ? <YouTrain /> : <></>}
            {profileType ? <></> : <ConnectedDevice />}
            {profileType ? <></> : <MyCoaching />}
            {profileType ? <></> : <MyEvent />}
            <ViewAccFooter profileType={profileType} />
          </div>
        )}
      </div>
    </HelmetProvider>
  );
}

export default ViewAccount;
