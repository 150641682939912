import { createSlice } from "@reduxjs/toolkit";

export const initialState = {};

const CoachFormSlice = createSlice({
  name: "getCoachFormData",
  initialState: initialState,
  reducers: {
    CoachFormData: (state, action) => {
      // state = action.payload.viewAccountData;
      return { ...action.payload.getCoachFromData };
    },
    addCoachFormData: (state, action) => {
      // Ensure only existing properties are updated
      Object.keys(action.payload).forEach((key) => {
        if (key in state) {
          state[key] = action.payload[key];
        }
      });
    },
  },
});

export const { CoachFormData, addCoachFormData } = CoachFormSlice.actions;
export default CoachFormSlice.reducer;
