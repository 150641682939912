import React, { useState } from "react";

function EditFormYouTrain(props) {
  const {
    getData,
    run,
    setRun,
    swim,
    setSwim,
    bike,
    setBike,
    triathlon,
    setTriathlon,
    editClicked,
  } = props;
  const [value, setValue] = useState("");

  const handleRun = (value) => {
    // If the value is already selected, remove it from the array
    if (run.includes(value)) {
      setRun(run.filter((item) => item !== value));
    } else {
      // Otherwise, add it to the array
      setRun([...run, value]);
    }
  };

  const handleSwim = (value) => {
    // If the value is already selected, remove it from the array
    if (swim.includes(value)) {
      setSwim(swim.filter((item) => item !== value));
    } else {
      // Otherwise, add it to the array
      setSwim([...swim, value]);
    }
  };

  const handleBike = (value) => {
    // If the value is already selected, remove it from the array
    if (bike.includes(value)) {
      setBike(bike.filter((item) => item !== value));
    } else {
      // Otherwise, add it to the array
      setBike([...bike, value]);
    }
  };

  const handletriathlon = (value) => {
    // If the value is already selected, remove it from the array
    if (triathlon.includes(value)) {
      setTriathlon(triathlon.filter((item) => item !== value));
    } else {
      // Otherwise, add it to the array
      setTriathlon([...triathlon, value]);
    }
  };

  return (
    <>
      <div className="input_info_box">
        <div className="first_half ">
          <div className="edit_lable">
            <p className="lable_tag">Run</p>
          </div>

          {getData.coaches ? (
            <>
              <div className="run_values">
                {getData.coaches.run ? <p> {run.join(", ")}</p> : <></>}
              </div>

              {editClicked && (
                <div className="grid custom-grid1 pr-4 mt-2 check_box_run">
                  <label>
                    <input className="opacity-0 hidden "
                      type="checkbox"
                      name="radio"
                      id=""
                      value="5k"
                      onChange={() => handleRun("5k")}
                    />

                    <span 
                      className={`${run.includes("5k") ? "!bg-[var(--carmine)]" : ""} p-2`}
                      >
                      5k
                    </span>
                  </label>

                  <label>
                    <input className="opacity-0 hidden"
                      type="checkbox"
                      name="radio"
                      id=""
                      value="7k"
                      onChange={() => handleRun("7k")}
                    />
                    <span
                      className={`${run.includes("7k")?"!bg-[var(--carmine)]":""}`}

                    >
                      7k
                    </span>
                  </label>

                  <label>
                    <input className="opacity-0 hidden"
                      type="checkbox"
                      name="radio"
                      id=""
                      value="10k"
                      onChange={() => handleRun("10k")}
                    />
                    <span
                      className={`${run.includes("10k")?"!bg-[var(--carmine)]":""}`}
                      >
                      10k
                    </span>
                  </label>

                  <label>
                    <input className="opacity-0 hidden"
                      type="checkbox"
                      name="radio"
                      id=""
                      value="16k"
                      onChange={() => handleRun("16k")}
                    />
                    <span
                      className={`${run.includes("16k")?"!bg-[var(--carmine)]":""}`}
                      >
                      16k
                    </span>
                  </label>

                  <label>
                    <input className="opacity-0 hidden"
                      type="checkbox"
                      name="radio"
                      id=""
                      value="21k"
                      onChange={() => handleRun("21k")}
                    />
                    <span
                      className={`${run.includes("21k")?"!bg-[var(--carmine)]":""}`}
                      >
                      21k
                    </span>
                  </label>

                  <label>
                    <input className="opacity-0 hidden"
                      type="checkbox"
                      name="radio"
                      id=""
                      value="42k"
                      onChange={() => handleRun("42k")}
                    />
                    <span
                      className={`${run.includes("42k")?"!bg-[var(--carmine)]":""}`}
                      
                    >
                      42k
                    </span>
                  </label>
                </div>
              )}
            </>
          ) : (
            <></>
          )}
        </div>
        <div className="second_half ">
          <p className="lable_tag">Swim</p>

          {getData.coaches ? (
            <>
              <div className="run_values">
                {getData.coaches.swim ? <p> {swim.join(", ")}</p> : <></>}
              </div>

              {editClicked && (
                <div className="grid grid-cols-6 pr-4 mt-2 check_box_run">
                  <label>
                    <input className="opacity-0 hidden"
                      type="checkbox"
                      name="radio"
                      id=""
                      value="1k"
                      onChange={() => handleSwim("1k")}
                    />

                    <span
                      className={`${swim.includes("1k")?"!bg-[var(--sky-blue)]":""}`}
                    >
                      1k
                    </span>
                  </label>

                  <label>
                    <input className="opacity-0 hidden"
                      type="checkbox"
                      name="radio"
                      id=""
                      value="1.5k"
                      onChange={() => handleSwim("1.5k")}
                    />
                    <span
                      className={`${swim.includes("1.5k")?"!bg-[var(--sky-blue)]":""}`}
                      >
                      1.5k
                    </span>
                  </label>

                  <label>
                    <input className="opacity-0 hidden"
                      type="checkbox"
                      name="radio"
                      id=""
                      value="1.9k"
                      onChange={() => handleSwim("1.9k")}
                    />
                    <span
                      className={`${swim.includes("1.9k")?"!bg-[var(--sky-blue)]":""}`}
                      >
                      1.9k
                    </span>
                  </label>

                  <label> 
                    <input className="opacity-0 hidden"
                      type="checkbox"
                      name="radio"
                      id=""
                      value="3.8k"
                      onChange={() => handleSwim("3.8k")}
                    />
                    <span
                      className={`${swim.includes("3.8k")?"!bg-[var(--sky-blue)]":""}`}
                      >
                      3.8k
                    </span>
                  </label>

                  <label>
                    <input className="opacity-0 hidden"
                      type="checkbox"
                      name="radio"
                      id=""
                      value="5k"
                      onChange={() => handleSwim("5k")}
                    />
                    <span
                      className={`${swim.includes("5k")?"!bg-[var(--sky-blue)]":""}`}
                      >
                      5k
                    </span>
                  </label>
                </div>
              )}
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className="input_info_box">
        <div className="first_half padding_right">
          <p className="lable_tag">Cycling</p>

          {getData.coaches ? (
            <>
              <div className="run_values">
                {getData.coaches.bike ? <p> {bike.join(", ")}</p> : <></>}
              </div>

              {editClicked && (
                <div className="grid grid-cols-6 pr-4 mt-2 check_box_run">
                  <label>
                    <input  className="opacity-0 hidden"
                      type="checkbox"
                      name="radio"
                      id=""
                      value="40k"
                      onChange={() => handleBike("40k")}
                    />
                    <span
                       className={`${bike.includes("40k")?"!bg-[var(--neon)]":""}`}
                    >
                      40k
                    </span>
                  </label>

                  <label>
                    <input  className="opacity-0 hidden"
                      type="checkbox"
                      name="radio"
                      id=""
                      value="90k"
                      onChange={() => handleBike("90k")}
                    />
                    <span
                       className={`${bike.includes("90k")?"!bg-[var(--neon)]":""}`}
                       >
                      90k
                    </span>
                  </label>

                  <label>
                    <input  className="opacity-0 hidden"
                      type="checkbox"
                      name="radio"
                      id=""
                      value="100k"
                      onChange={() => handleBike("100k")}
                    />
                    <span
                       className={`${bike.includes("100k")?"!bg-[var(--neon)]":""}`}
                       >
                      100k
                    </span>
                  </label>

                  <label>
                    <input  className="opacity-0 hidden"
                      type="checkbox"
                      name="radio"
                      id=""
                      value="160k"
                      onChange={() => handleBike("160k")}
                    />
                    <span
                       className={`${bike.includes("160k")?"!bg-[var(--neon)]":""}`}
                       >
                      160k
                    </span>
                  </label>

                  <label>
                    <input  className="opacity-0 hidden"
                      type="checkbox"
                      name="radio"
                      id=""
                      value="200k"
                      onChange={() => handleBike("200k")}
                    />
                    <span
                       className={`${bike.includes("200k")?"!bg-[var(--neon)]":""}`}
                       >
                      200k
                    </span>
                  </label>

                  <label>
                    <input  className="opacity-0 hidden"
                      type="checkbox"
                      name="radio"
                      id=""
                      value="Time Trail"
                      onChange={() => handleBike("Time Trail")}
                    />
                    <span
                      className={`${bike.includes("Time Trail")?"!bg-[var(--neon)]":""}`}
                    >
                      TT
                    </span>
                  </label>
                </div>
              )}
            </>
          ) : (
            <></>
          )}
        </div>
        <div className="second_half">
          <p className="lable_tag">Triathlon</p>

          {getData.coaches ? (
            <>
              <div className="run_values">
                {getData.coaches.triathlon ? (
                  <p> {triathlon.join(", ")}</p>
                ) : (
                  <></>
                )}
              </div>

              {editClicked && (
                <div className="check_box_t">
                  <label>
                    <input className="opacity-0 hidden"
                      type="checkbox"
                      name="radio"
                      id=""
                      value="Sprint Tri"
                      onChange={() => handletriathlon("Sprint Tri")}
                    />
                    <span
                       className={`${triathlon.includes("Time Trail")?"!bg-[var(--neon)]":""}`}
                      >
                      Sprint Tri
                    </span>
                  </label>

                  <label>
                    <input className="opacity-0 hidden"
                      type="checkbox"
                      name="radio"
                      id=""
                      value="5150 ironman"
                      onChange={() => handletriathlon("5150 ironman")}
                    />
                    <span
                      className={`${triathlon.includes("5150 ironman")?"!bg-[var(--yellow)]":""}`}
                    >
                      5150 ironman
                    </span>
                  </label>

                  <label>
                    <input className="opacity-0 hidden"
                      type="checkbox"
                      name="radio"
                      id=""
                      value="Ironman 70.3"
                      onChange={() => handletriathlon("Ironman 70.3")}
                    />
                    <span
                      className={`${triathlon.includes("Ironman 70.3")?"!bg-[var(--yellow)]":""}`}
                    >
                      Ironman 70.3
                    </span>
                  </label>

                  <label>
                    <input className="opacity-0 hidden"
                      type="checkbox"
                      name="radio"
                      id=""
                      value="Ironman"
                      onChange={() => handletriathlon("Ironman")}
                    />
                    <span
                      className={`${triathlon.includes("7k")?"!bg-[var(--yellow)]":""}`}
                    >
                      Ironman
                    </span>
                  </label>
                </div>
              )}
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
}

export default EditFormYouTrain;
