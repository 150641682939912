import React from "react";
import AppleStore from "assets/img/apple-store.png";
import GooglePlay from "assets/img/play-store2.png";
import logo from "assets/img/tofAppIcon 1.png";

function AboutUs() {
  return (
    <>
      <div className="about_section" id="about">
        <div className="about_bg_img flex items-center px-[2rem] py-[5rem] sm:px-[5rem]">
          <div className="about_header_text">
            <div className="header_content">
              <div>
                <h2 className="inline-block h-auto font-normals text-[3rem] text-[var(--white)]">
                  Hii, We are
                </h2>{" "}
                <span className="payToneOne text-[3rem] text-[var(--white)] ml-1">
                  TRACKOFIT
                </span>
              </div>

              <p className="text-[var(--white)] w-[50%] text-[1.5rem]">
                Trackofit is your ultimate destination for triathlon training
                excellence.
              </p>
              <div className=" flex mt-8">
                <a href="https://apps.apple.com/in/app/trackofit/id6469041232">
                  <img className=" w-[6rem]" src={AppleStore} alt="" />
                </a>
                <a href="https://play.google.com/store/apps/details?id=com.isf.trackofit&pli=1">
                  <img className="w-[7rem] ml-[1rem]" src={GooglePlay} alt="" />
                </a>
                <img className="logo_phone" src={logo} alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className="about_content flex my-[2rem] px-4">
          <div className="">
            <div className="about_info_para">
              <h3>What is TRACKOFIT?</h3>
              <p className="para">
                Trackofit is your ultimate destination for triathlon training
                excellence. Whether you're an aspiring athlete looking to
                enhance your performance or a seasoned coach ready to inspire
                and guide others, Tackofit brings together a community
                passionate about triathlons. It's a dynamic platform designed to
                connect coaches and athletes, fostering a supportive environment
                for growth, achievement, and success in the world of triathlon
                and many more.
              </p>
            </div>
          </div>

          <div>
            <div className="about_info_para">
              <h3> Why TRACKOFIT ?</h3>
              <p className="para">
                Trackofit stands out as a comprehensive solution tailored
                specifically for the triathlon community. Access personalized
                training programs crafted by experienced coaches to suit your
                unique goals, abilities, and schedule. Connect with seasoned
                coaches who bring a wealth of experience and knowledge to help
                you reach your full potential in swimming, cycling, running, and
                overall performance. Enjoy the flexibility of training anytime,
                anywhere, with access to our platform from your desktop or
                mobile device.
              </p>
            </div>
          </div>

          <div>
            <div className="about_info_para">
              <h3>Our mission</h3>
              <p className="para">
                At Trackofit, our goal is simple yet ambitious: to empower
                athletes and coaches to excel in the world of triathlons.
                Provide a platform where athletes can grow and develop their
                skills under the guidance of expert coaches. Foster a supportive
                and inclusive community where athletes and coaches can connect,
                learn from each other, and thrive together. Inspire individuals
                to push beyond their limits, set new personal bests, and achieve
                their triathlon dreams.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AboutUs;
