import React, { useContext, useEffect } from "react";
import { SubmitCoachFormData } from "utility/constants/useContext";
import { updateForm } from "state/SetCoachFormData";

function InputBox() {
  const Props = useContext(SubmitCoachFormData);

  function setValues(e) {
    Props.dispatch(updateForm({ [e.target.name]: e.target.value }));
  }

  return (
    <div className="name_inputs !pt-1.5">
      <div className="name mt-[-0.1rem]">
        <label htmlFor="input" className="text !top-2	">
          Name<sup className="text-red-500"> *</sup>
        </label>
        <input
          type="text"
          placeholder=""
          name="name"
          className="input"
          value={Props.formState.name}
          onChange={(e) => setValues(e)}
          required
        />
      </div>

      <div className="tagline mt-[-0.4rem]">
        <label htmlFor="input" className="text">
          Tagline<sup className="text-[#d0312d]"> *</sup>
        </label>
        <input
          type="text"
          placeholder=""
          name="tagline"
          className="input mt-1"
          value={Props.formState.tagline}
          onChange={(e) => setValues(e)}
          required
        />
      </div>
    </div>
  );
}

export default InputBox;
