import React, { useState, useEffect } from "react";
import Edit from "assets/icons/edit.png";
import ErrorPopup from "components/popups/ErrorPopup";
import { useSelector } from "react-redux";
import { useCoverHandlers } from "utility/utility_functions/coverPhoto";
import { usePhotoHandlers } from "utility/utility_functions/profilePhotos";
import { API_URL_CONSTANTS } from "utility/constants/apiUrlConstants";
import { getApis } from "hooks/api";
function CoverPhoto() {
  const [CoverImgSrc, setCoverImgSrc] = useState("");
  const [profileImgSrc, setProfileImgSrc] = useState("");
  useEffect(() => {
    const fetchDataAthlete = async () => {
      try {
        const resCoverPhoto = await getApis(API_URL_CONSTANTS.GET_COVER_IMG);

        setCoverImgSrc(resCoverPhoto.data.message);

        const resProfilePhoto = await getApis(
          API_URL_CONSTANTS.GET_PROFILE_IMG
        );

        setProfileImgSrc(resProfilePhoto.data.message);
      } catch (error) {
        return error;
      }
    };
    fetchDataAthlete();
  }, []);

  const {
    handleProfilePhoto,
    profilePhotoURL,
    errorPopup: profileErrorPopup,
    errorMessage: profileErrorMessage,
    errorHeader: profileErrorHeader,
    setErrorPopup: setProfileErrorPopup,
  } = usePhotoHandlers();
  const {
    photoURL,
    handleFileChange,
    errorPopup: coverErrorPopup,
    errorMessage: coverErrorMessage,
    errorHeader: coverErrorHeader,
    setErrorPopup: setCoverErrorPopup,
  } = useCoverHandlers();

  return (
    <>
      {(profileErrorPopup || coverErrorPopup) && (
        <>
          <div className="blur"></div>
          <ErrorPopup
            message={{
              heading: profileErrorPopup
                ? profileErrorHeader
                : coverErrorHeader,
              message: profileErrorPopup
                ? profileErrorMessage
                : coverErrorMessage,
            }}
            setErrorPopup={
              profileErrorPopup ? setProfileErrorPopup : setCoverErrorPopup
            }
          />
        </>
      )}
      <div
        className="cover_photo cover_photo_edit"
        style={{ marginTop: "4rem" }}
      >
        {/* {getData.cover_photo && ( */}
        <>
          {CoverImgSrc && (
            <img
              src={
                photoURL
                  ? photoURL
                  : `${API_URL_CONSTANTS.DOMAIN_NAME}${CoverImgSrc}`
              }
              className="cover_photo_img"
            />
          )}

          <div className="edit_logo_cover">
            <div className="edit_cricle">
              <input
                id="file-input"
                type="file"
                accept="image/*"
                onChange={(event) => handleFileChange(event, "edit", "")}
                style={{ display: "none" }}
              />
              <label className="c" htmlFor="file-input">
                <img src={Edit} alt="" />
              </label>
            </div>
          </div>
        </>
        {/* )} */}
        <div className="profile_input_box">
          <div className={`profile_photo `}>
            {" "}
            {profileImgSrc && (
              <img
                src={
                  profilePhotoURL
                    ? profilePhotoURL
                    : `${API_URL_CONSTANTS.DOMAIN_NAME}${profileImgSrc}`
                }
                className="profile_photo_img"
              />
            )}
            <div className="edit_logo_profile">
              <input
                type="file"
                accept="image/*"
                name=""
                placeholder="+"
                id="profilePhoto"
                onChange={(event) => handleProfilePhoto(event, "edit", "")}
                style={{ display: "none" }}
              />
              <label className=" edit_logo_cover" htmlFor="profilePhoto">
                <div className="edit_cricle">
                  <img src={Edit} alt="" />
                </div>
              </label>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default CoverPhoto;
