import { useState } from "react";
import { ERROR_CONSTANTS } from "utility/constants/errorConstants.js";
import { SubmitVideo } from "api/CoachFormController";
import { useSelector, useDispatch } from "react-redux";
import { updateForm } from "state/SetCoachFormData";

export const useVideoHandlers = () => {
  const dispatch = useDispatch();
  const [videoFileURL, setVideoFileURL] = useState("");
  const [errorHeader, setErrorHeader] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [errorPopup, setErrorPopup] = useState(false);

  const checkStatusVideo = async (file, setVideoPath) => {
    const req = await SubmitVideo(file);
    if (req.success === true) {
      setVideoFileURL(URL.createObjectURL(file));
      setVideoPath(req.path);

      dispatch(updateForm({ videoPath: req.path }));
      dispatch(updateForm({ getVideo: URL.createObjectURL(file) }));
    } else {
      setErrorHeader(ERROR_CONSTANTS.NetworkError.Heading);
      setErrorMessage(ERROR_CONSTANTS.NetworkError.Message);
      setErrorPopup(true);
    }
  };

  const handleFileChange = (event, setVideoPath) => {
    const file = event.target.files[0]; // Get the selected file

    if (file) {
      // Validate file size
      const fileSize = file.size;

      if (fileSize < 10000 || fileSize > 5000000) {
        setErrorHeader("Video Error");
        setErrorMessage(
          "Video size must be between 10,000 bytes and 50,000 bytes."
        );
        setErrorPopup(true);
        return; // Exit the function if size is not valid
      }

      // Validate file type
      if (file.type.startsWith("video/")) {
        const videoElement = document.createElement("video");

        const handleMetadataLoaded = () => {
          const durationInSeconds = Math.round(videoElement.duration);

          videoElement.removeEventListener(
            "loadedmetadata",
            handleMetadataLoaded
          );

          if (durationInSeconds <= 60) {
            checkStatusVideo(file, setVideoPath);
          } else {
            setErrorHeader("Video Error");
            setErrorMessage(
              "Video duration exceeds the maximum allowed duration. Video should be less than 60s."
            );
            setErrorPopup(true);
          }
        };

        videoElement.addEventListener("loadedmetadata", handleMetadataLoaded);
        videoElement.src = URL.createObjectURL(file);
      } else {
        setErrorHeader("Video Error");
        setErrorMessage("Please select a valid video file.");
        setErrorPopup(true);
      }
    } else {
      setErrorHeader("Video Error");
      setErrorMessage("No file selected.");
      setErrorPopup(true);
    }
  };

  // const handleFileChange = (event, setVideoPath) => {
  //   const file = event.target.files[0]; // Get the selected file

  //   if (file && file.type.startsWith("video/")) {
  //     const videoElement = document.createElement("video");

  //     const handleMetadataLoaded = () => {
  //       const durationInSeconds = Math.round(videoElement.duration);

  //       videoElement.removeEventListener(
  //         "loadedmetadata",
  //         handleMetadataLoaded
  //       );

  //       if (durationInSeconds <= 60) {
  //         checkStatusVideo(file, setVideoPath);
  //       } else {
  //         setErrorHeader("Video Error");
  //         setErrorMessage(
  //           "Video duration exceeds the maximum allowed duration. Video should be less than 60s."
  //         );
  //         setErrorPopup(true);
  //       }
  //     };

  //     videoElement.addEventListener("loadedmetadata", handleMetadataLoaded);

  //     videoElement.src = URL.createObjectURL(file);
  //   } else {
  //     setErrorHeader("Video Error");
  //     setErrorMessage("Please select a valid video file.");
  //     setErrorPopup(true);
  //   }
  // };

  return {
    handleFileChange,
    videoFileURL,
    errorHeader,
    errorMessage,
    errorPopup,
    setErrorPopup,
  };
};
