import React, { useEffect, useState } from "react";
import video from "../../../assets/img/running_video.mp4";
import {
  DeleteCoachForm,
  getFormData,
  getVideo,
} from "api/CoachFormController";
import { Link } from "react-router-dom";
import Nav from "components/navbar/Nav";
import PhoneNavBar from "components/navbar/PhoneNavBar";
import PopUpLoginP from "components/signin/PhonePopUpLogin";
import { API_URL_CONSTANTS } from "utility/constants/apiUrlConstants";
import ActionPopup from "components/popups/ActionPopup";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { CoachFormData, initialState } from "state/CoachFormData";
import { getApis } from "hooks/api";
import { isDataEmpty } from "utility/utility_functions/isDataEmpty";

function ViewCoachForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [getData, setData] = useState([]);
  const [CoverImgSrc, setCoverImgSrc] = useState("");
  const [profileImgSrc, setProfileImgSrc] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  const [certifications, setCertifications] = useState([]);
  const [deletePopup, setDeletePopup] = useState(false);
  const [load, setLoad] = useState(false);
  const Data = useSelector((state) => state.getCoachFromData);

  // scroll to top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  //API call get data for coach Form
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getFormData();
        // setData(res.data);
        dispatch(CoachFormData({ getCoachFromData: res.data }));
        setCertifications(Data.certifications);

        const resCoverPhoto = await getApis(API_URL_CONSTANTS.GET_COVER_IMG);

        setCoverImgSrc(resCoverPhoto.data.message);

        const resProfilePhoto = await getApis(
          API_URL_CONSTANTS.GET_PROFILE_IMG
        );
        setProfileImgSrc(resProfilePhoto.data.message);

        if (res.data.video) {
          const resGetVideo = await getVideo();

          setVideoUrl(resGetVideo.message);
        }
      } catch (error) {
        return error;
      }
    };
    fetchData();
    // if (isDataEmpty(Data, initialState)) {
    //   console.log("api call");
    // } else {
    //   console.log("no Api call");
    // }
  }, []);

  async function handleRemove() {
    if (load === true) {
      return;
    }
    setLoad(true);
    const res = await DeleteCoachForm();
    if (res) {
      setLoad(true);
      setDeletePopup(false);
      navigate("/");
    } else {
      setLoad(false);
    }
  }

  return (
    <>
      <div className="coach_form">
        {/* <CoachFormNavBar isNavBar={isNavBar} /> */}
        <Nav />
        <PhoneNavBar />
        <PopUpLoginP />

        {Data === undefined ? (
          <div className="flex justify-center items-center h-[100vh]">
            <h3>No Data</h3>
          </div>
        ) : (
          <>
            {deletePopup && (
              <>
                <div className="delete_blur"></div>
                <ActionPopup
                  message={{
                    heading: "Delete Form",
                    message: `Do you really want to delete this form? This
          process cannot be undone`,
                  }}
                  setDeletePopup={setDeletePopup}
                  handleRemove={handleRemove}
                  load={load}
                />
              </>
            )}
            <div className="width_70_div">
              <div className="cover_photo mt-16">
                <img
                  src={
                    Data.cover_photo
                      ? `${API_URL_CONSTANTS.DOMAIN_NAME}${Data.cover_photo}`
                      : `${API_URL_CONSTANTS.DOMAIN_NAME}${CoverImgSrc}`
                  }
                  className="cover_photo_img"
                />

                <div className="profile_input_box">
                  <div className="profile_photo">
                    <img
                      src={
                        Data.profile_photo
                          ? `${API_URL_CONSTANTS.DOMAIN_NAME}${Data.profile_photo}`
                          : `${API_URL_CONSTANTS.DOMAIN_NAME}${profileImgSrc}`
                      }
                      className="profile_photo_img"
                    />
                  </div>
                </div>
              </div>

              {/* Personal detials */}
              <div className="delete_btn">
                <span onClick={() => setDeletePopup(true)}>Delete Request</span>
              </div>
              <div className="profile_box mt-0">
                <div className="profile_box_heading_div">
                  <h2>Personal Details</h2>
                </div>
                <div className="input_info_box input_info_box_profile">
                  <div className="first_half">
                    <p className="lable_tag">Name</p>
                    <p className="lable_value ">
                      {Data.name === undefined ? (
                        <span>&ndash;</span>
                      ) : (
                        `${Data.name}`
                      )}
                    </p>
                  </div>
                  <div className="second_half">
                    <p className="lable_tag">Tagline</p>
                    <p className="lable_value ">
                      {" "}
                      {Data.tagline === undefined ? (
                        <span>&ndash;</span>
                      ) : (
                        `${Data.tagline}`
                      )}
                    </p>
                  </div>
                </div>
                <div className="input_info_box input_info_box_profile">
                  <div className="first_half">
                    <p className="lable_tag">Phone</p>
                    <p className="lable_value ">
                      {Data.phone === undefined ? (
                        <span>&ndash;</span>
                      ) : (
                        `${Data.phone}`
                      )}
                    </p>
                  </div>
                  <div className="second_half">
                    <p className="lable_tag">Email</p>
                    <p className="lable_value ">
                      {" "}
                      {Data.email === undefined ? (
                        <span>&ndash;</span>
                      ) : (
                        `${Data.email}`
                      )}
                    </p>
                  </div>
                </div>
                <div className="input_info_box input_info_box_profile">
                  <div className="first_half">
                    <p className="lable_tag">Location</p>
                    <p className="lable_value ">
                      {Data.location === undefined ? (
                        <span>&ndash;</span>
                      ) : (
                        `${Data.location}`
                      )}
                    </p>
                  </div>
                  <div className="second_half">
                    <p className="lable_tag">Gender </p>
                    <p className="lable_value ">
                      {" "}
                      {Data.gender === undefined ? (
                        <span>&ndash;</span>
                      ) : (
                        `${Data.gender}`
                      )}
                    </p>
                  </div>
                </div>
              </div>

              <div className="profile_box">
                <div className="profile_box_heading_div">
                  <h2>About me</h2>
                </div>
                <div className="input_info_box input_info_box_profile">
                  <div className="about_box">
                    <p>
                      {Data.about === undefined ? (
                        <span>&ndash;</span>
                      ) : (
                        `${Data.about}`
                      )}
                    </p>
                  </div>
                </div>
              </div>

              {/* You Train */}
              <div className="profile_box">
                <div className="profile_box_heading_div">
                  <h2>You Train</h2>
                </div>

                <div className="input_info_box input_info_box_profile">
                  <div className="first_half">
                    {Data.coaches && Data.coaches.run ? (
                      <>
                        <p className="lable_tag">Run</p>
                        <p className="lable_value  lable_value ">
                          {Data.coaches.run.join(", ")}
                        </p>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="second_half">
                    {Data.coaches && Data.coaches.swim ? (
                      <>
                        <p className="lable_tag">Swim</p>
                        <p className="lable_value  lable_value ">
                          {Data.coaches.swim.join(", ")}
                        </p>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                <div className="input_info_box input_info_box_profile">
                  <div className="first_half">
                    {Data.coaches && Data.coaches.bike ? (
                      <>
                        <p className="lable_tag">Cycling</p>
                        <p className="lable_value  lable_value ">
                          {Data.coaches.bike.join(", ")}
                        </p>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="second_half">
                    {Data.coaches && Data.coaches.triathlon ? (
                      <>
                        <p className="lable_tag">Triathlon</p>
                        <p className="lable_value  lable_value ">
                          {Data.coaches.triathlon.join(", ")}
                        </p>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>

              {/* certificates */}
              {Data.certifications ? (
                <>
                  <div className="setting_box">
                    <div className="profile_box_heading_div">
                      <h2>Certifications</h2>
                    </div>
                    {Data.certifications.map((item) => (
                      <div className="certificate_box" key={item.id}>
                        <div className="certificate_name">
                          <p className="profile_label">Name</p>
                          <p className="lable_value ">{item.name}</p>
                        </div>
                        <div className="issued_by">
                          <p className="profile_label">Issued By</p>
                          <p className="lable_value ">{item.issued_by}</p>
                        </div>
                        <div className="date">
                          <p className="profile_label">Date</p>
                          <p className="lable_value ">{item.issued_on}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              ) : (
                <></>
              )}

              {/* Video */}
              <div className="profile_box mt-16 mb-0">
                <div className="profile_box_heading_div">
                  <h2>Video</h2>
                </div>
                <div className="input_info_box input_info_box_profile">
                  <div className="video_box">
                    <div className="video_div m-0">
                      <video
                        id="video"
                        src={`${API_URL_CONSTANTS.DOMAIN_NAME}${Data.video}`}
                        controls
                      ></video>
                    </div>
                  </div>
                </div>
              </div>
              <div className="view_form_back_btn">
                <Link to="/">&#8592; Back</Link>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default ViewCoachForm;
