import React, { useEffect, useState } from "react";
import ActionPopup from "components/popups/ActionPopup";
import "../coaching-plan.css";
import ErrorPopup from "components/popups/ErrorPopup";
import {
  DeleteCoahingPlan,
  getCoachingPlans,
} from "api/CoachingPlansController";
import parse from "html-react-parser";

// Utility function to extract text from parsed HTML
const extractText = (nodes) => {
  let text = "";
  const recurse = (node) => {
    if (typeof node === "string") {
      text += node;
    } else if (Array.isArray(node)) {
      node.forEach(recurse);
    } else if (node && node.props && node.props.children) {
      recurse(node.props.children);
    }
  };
  recurse(nodes);
  return text;
};

function CoachingPlanCard(props) {
  const { handleEdit, add, handleRevomeId, planId } = props;
  const [data, setData] = useState([]);
  const [deletePopup, setDeletePopup] = useState(false);
  const [showMore, setShowMore] = useState({});
  const [errorPopup, setErrorPopup] = useState(false); // error popup
  const [errorHeader, setErrorHeader] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const [planName, setPlanName] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getCoachingPlans();
        setData(res.data.coaching_plans);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [add, deletePopup]);

  const handleRemove = async () => {
    const res = await DeleteCoahingPlan(planId);
    if (res) {
      setDeletePopup(false);
    } else {
      setErrorHeader("Not deleted");
      setErrorMessage("");
      setErrorPopup(true);
    }
  };

  const handleShowMore = (planId) => {
    setShowMore((prevShowMore) => ({
      ...prevShowMore,
      [planId]: !prevShowMore[planId],
    }));
  };

  return (
    <>
      {errorPopup && (
        <>
          <div className="blur"></div>
          <ErrorPopup
            message={{
              heading: errorHeader,
              message: errorMessage,
            }}
            setErrorPopup={setErrorPopup}
          />
        </>
      )}
      {data.length === 0 ? (
        <></>
      ) : (
        <>
          {data.map((plan) => (
            <div key={plan.plan_id}>
              {deletePopup && (
                <>
                  <div className="delete_blur"></div>
                  <ActionPopup
                    message={{
                      heading: "Delete Plan",
                      message: `Do you really want to delete "${planName}" plan? This
                        process cannot be undone`,
                    }}
                    setDeletePopup={setDeletePopup}
                    handleRemove={handleRemove}
                  />
                </>
              )}

              <div className="card_body">
                <div className="count_btn">
                  {plan.type === 1 && (
                    <>
                      {plan.active_count === plan.max_count ? (
                        <span className="text-[#FF0000]">
                          Active{" "}
                          <b>
                            {" "}
                            {plan.active_count}/{plan.max_count}
                          </b>
                        </span>
                      ) : (
                        <span>
                          Active{" "}
                          <b>
                            {plan.active_count}/{plan.max_count}
                          </b>
                        </span>
                      )}
                    </>
                  )}
                  {plan.type === 2 && (
                    <span>
                      Active <b>{plan.active_count}</b>
                    </span>
                  )}
                </div>

                {plan.type === 1 ? (
                  <div className="edit_remove_div opacity-0">
                    <p className="edit_btn">e</p>
                    <p className="remove_btn">r</p>
                  </div>
                ) : (
                  <div>
                    <div className="edit_remove_div">
                      <p
                        className="remove_btn"
                        onClick={() => {
                          handleRevomeId(plan.plan_id);
                          setDeletePopup(true);
                          setPlanName(plan.plan_name);
                        }}
                      >
                        Remove
                      </p>
                      <p
                        className="edit_btn"
                        onClick={() => {
                          handleEdit(
                            plan.plan_id,
                            plan.plan_name,
                            plan.description,
                            plan.plan_charge
                          );
                        }}
                      >
                        Edit
                      </p>
                    </div>
                  </div>
                )}

                <div className="card_main">
                  <div className="card_heading">
                    <h4 className="break-words">{plan.plan_name}</h4>
                    <div className="card_info">
                      {plan.description && (
                        <p>
                          {plan.description.length < 300 ? (
                            parse(plan.description)
                          ) : (
                            <>
                              {showMore[plan.plan_id]
                                ? parse(plan.description)
                                : `${extractText(
                                    parse(plan.description)
                                  ).substring(0, 300)}...`}
                              <span
                                className="text-[.8rem] cursor-pointer text-[#0a8dff]"
                                onClick={() => handleShowMore(plan.plan_id)}
                              >
                                {showMore[plan.plan_id]
                                  ? " show less"
                                  : " show more"}
                              </span>
                            </>
                          )}
                        </p>
                      )}
                    </div>
                  </div>
                  {plan.type === 1 ? (
                    <div className="card_btn">
                      <p>Free </p>
                    </div>
                  ) : (
                    <div className="card_btn_main">
                      <p>
                        ₹ {plan.plan_charge}{" "}
                        <span className="text-[.9rem]">per month</span>
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}
        </>
      )}
    </>
  );
}

export default CoachingPlanCard;
