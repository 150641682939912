import axios from "axios";
import { APP_CONSTANTS } from "utility/constants/appConstants";
import { API_URL_CONSTANTS } from "utility/constants/apiUrlConstants";
import { Header } from "api/Signin";
import { getToke } from "api/Signin";
import jwt_decode from "jwt-decode";

export async function EditCoverPhoto(file) {
  const token = getToke();
  const headers = {
    Accept: "*/*",
    "Content-Type": "multipart/form-data",
    Authorization: `Bearer ${token}`,
  };
  let formData = new FormData();
  formData.append("file", file);
  return axios
    .post(API_URL_CONSTANTS.EDIT_PHOTO + "1", formData, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.status_code === 1) {
        return res;
      } else {
        return false;
      }
    })
    .catch((error) => {
      return error;
    });
}

export async function EditProfilePhoto(file) {
  const token = getToke();
  const headers = {
    Accept: "*/*",
    "Content-Type": "multipart/form-data",
    Authorization: `Bearer ${token}`,
  };
  let formData = new FormData();
  formData.append("file", file);
  return axios
    .post(API_URL_CONSTANTS.EDIT_PHOTO + "2", formData, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.status_code === 1) {
        return res;
      } else {
        return false;
      }
    })
    .catch((error) => {
      return error;
    });
}

export async function SubmitCoverPhoto(file) {
  const token = getToke();
  const headers = {
    Accept: "*/*",
    "Content-Type": "multipart/form-data",
    Authorization: `Bearer ${token}`,
  };
  let formData = new FormData();
  formData.append("file", file);
  return axios
    .post(API_URL_CONSTANTS.COACHFORM_PHOTO_UPLOAD_API + "/1", formData, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.status_code === 1) {
        return { path: res.data.path, success: true };
      } else {
        return { path: null, success: false };
      }
    })
    .catch((error) => {
      return error;
    });
}

export async function SubmitProfilePhoto(file) {
  const token = getToke();
  const headers = {
    Accept: "*/*",
    "Content-Type": "multipart/form-data",
    Authorization: `Bearer ${token}`,
  };
  let formData = new FormData();
  formData.append("file", file);
  return axios
    .post(API_URL_CONSTANTS.COACHFORM_PHOTO_UPLOAD_API + "/2", formData, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.status_code === 1) {
        return { path: res.data.path, success: true };
      } else {
        return { path: null, success: false };
      }
    })
    .catch((error) => {
      return error;
    });
}

export async function SubmitVideo(file) {
  const token = getToke();
  const headers = {
    Accept: "*/*",
    "Content-Type": "multipart/form-data",
    Authorization: `Bearer ${token}`,
  };
  let formData = new FormData();
  formData.append("file", file);
  return axios
    .post(API_URL_CONSTANTS.COACHFORM_PHOTO_UPLOAD_API + "/3", formData, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.status_code === 1) {
        return { path: res.data.path, success: true };
      } else {
        return { path: null, success: false };
      }
    })
    .catch((error) => {
      return error;
    });
}

export async function SubmitCoachForm(
  name,
  tagline,
  email,
  number,
  location,
  gender,
  aboutYourSelf,
  selectedDistancesRun,
  selectedDistancesSwim,
  selectedDistancesCycle,
  selectedDistancesTriathlon,
  coverPhotoPath,
  profilePhotoPath,
  videoPath,
  certificates,
  TAC
) {
  const header = Header();

  return axios
    .post(
      API_URL_CONSTANTS.COACHFORM_FORM_SUBMIT_API,
      {
        name: name,
        tagline: tagline,
        email: email,
        phone: number,
        location: location,
        gender: gender,
        about: aboutYourSelf,
        services_offered: {
          persionalized: 1, // 1 or 0 1 for yes, 0 for no
          standard: 1, // 1 or 0 1 for yes, 0 for no
        },
        certifications: certificates,
        coaches: {
          run: selectedDistancesRun,
          bike: selectedDistancesCycle,
          swim: selectedDistancesSwim,
          triathlon: selectedDistancesTriathlon,
        },
        profile_photo: profilePhotoPath,
        cover_photo: coverPhotoPath, //coverPhotoPath,
        video: videoPath,
        status: null, // 0 pending verification  , 1 verified, 2 rejected,
        send_marketing: 1,
        accept_tnc: TAC,
      },
      {
        headers: header,
      }
    )

    .then((response) => {
      if (response.data.status_code === 1) {
        return true;
      } else if (response.data.status_code === 2) {
        return false;
      }
    })
    .catch((error) => {
      return error;
    });
}

export async function getFormData() {
  const header = Header();
  return axios
    .get(API_URL_CONSTANTS.GET_COACH_FORM_DATA, {
      headers: header,
    })
    .then((res) => {
      if (res.status === 200) {
        return res;
      }
    })
    .catch((error) => {
      return error;
    });
}

// export async function getCoverPhoto() {
//   const header = Header();
//   return axios
//     .get(API_URL_CONSTANTS.GET_COVER_IMG, {
//       headers: header,
//     })
//     .then((res) => {
//       if (res.status === 200) {
//         return res;
//       }
//     })
//     .catch((error) => {
//       return error;
//     });
// }

// export async function getProfilPhoto() {
//   const header = Header();
//   return axios
//     .get(API_URL_CONSTANTS.GET_PROFILE_IMG, {
//       headers: header,
//     })
//     .then((res) => {
//       if (res.status === 200) {
//         return res;
//       }
//     })
//     .catch((error) => {
//       return error;
//     });
// }

export async function getVideo() {
  const header = Header();

  return axios
    .get(API_URL_CONSTANTS.GET_VIDEO, {
      headers: header,
    })
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      }
    })
    .catch((error) => {
      return error;
    });
}

export async function SwitchAccount(ref_id) {
  const header = Header();
  const id = ref_id;

  return axios
    .get(API_URL_CONSTANTS.SWITCH_ACCOUNT + `${id}`, {
      headers: header,
    })
    .then((res) => {
      if (res.status === 200) {
        return res.data.token;
      }
    })
    .catch((error) => {
      return error;
    });
}

export async function SetDataInlocalStorage(token, RememberMe) {
  const decodedToken = jwt_decode(token);
  const userEmail = decodedToken["email"];
  const userName = decodedToken["name"];
  const userRole = decodedToken["role"];
  const ref_id = decodedToken["id"];
  if (RememberMe === true) {
    localStorage.setItem(APP_CONSTANTS.JWT_TOKEN, token);
    localStorage.setItem("email", userEmail);
    localStorage.setItem("name", userName.trim());
    localStorage.setItem("role", userRole);
    localStorage.setItem("ref_id", ref_id);
    localStorage.setItem("remember_me", RememberMe);
  } else {
    sessionStorage.setItem(APP_CONSTANTS.JWT_TOKEN, token);
    sessionStorage.setItem("email", userEmail);
    sessionStorage.setItem("name", userName.trim());
    sessionStorage.setItem("role", userRole);
    sessionStorage.setItem("ref_id", ref_id);
    sessionStorage.setItem("remember_me", RememberMe);
  }
}

export async function DeleteCoachForm() {
  const header = Header();
  const ref_id =
    localStorage.getItem("ref_id") ?? sessionStorage.getItem("ref_id");

  return axios
    .post(
      API_URL_CONSTANTS.DELETE_COACH_FORM,
      {
        ref: ref_id,
        status: 5,
        message: "Updated for reason.",
      },
      {
        headers: header,
      }
    )
    .then((res) => {
      if (res.data.status_code === 1) {
        return true;
      }
    })
    .catch((error) => {
      return error;
    });
}
