import React from "react";
import { useNavigate } from "react-router-dom";

function InformationPopup(props) {
  const { message, setErrorPopup, errorPopup } = props;
  const navigate = useNavigate();
  return (
    <div>
      <div className="information_popup">
        <div className="heading_popup">
          <p>{message.heading}</p>
        </div>
        <div className="information_message">
          <p>{message.message}</p>
        </div>
        <div className="action_btns">
          {errorPopup ? (
            <span
              onClick={() => {
                setErrorPopup(false);
              }}
            >
              Done
            </span>
          ) : (
            <span
              onClick={() => {
                navigate("/");
              }}
            >
              Done
            </span>
          )}
        </div>
      </div>
    </div>
  );
}

export default InformationPopup;
