import axios from "axios";

export const getlocalStorageData = () => {
  const name = localStorage.getItem("name") ?? sessionStorage.getItem("name");

  const role = localStorage.getItem("role") ?? sessionStorage.getItem("role");
  const ref_id =
    localStorage.getItem("ref_id") ?? sessionStorage.getItem("ref_id");
  const email =
    localStorage.getItem("email") ?? sessionStorage.getItem("email");

  const localData = {
    name: name,
    role: role,
    ref_id: ref_id,
    email: email,
  };

  return localData;
};

export const fetchCity = async () => {
  try {
    const response = await axios.get("https://ipapi.co/json/");
    return {
      city: response.data.city,
      country: response.data.country_name,
      region: response.data.region,
    };
  } catch (error) {
    console.error("Error fetching the city:", error);
  }
};
