import { useContext, useEffect, useState } from "react";
import Logo from "components/logo/Logo";
import { Link } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";
import ActionPopup from "components/popups/ActionPopup";
import { DeleteCoachForm } from "api/CoachFormController";
import ErrorPopup from "components/popups/ErrorPopup";
import { ERROR_CONSTANTS } from "utility/constants/errorConstants";
import ProfileBox from "components/profileBox/ProfileBox";
import { getlocalStorageData } from "hooks/basic";
import { AppProps } from "utility/constants/useContext";

function Nav(props) {
  const { setSignUp, isSignUp } = props;
  const data = useContext(AppProps);
  const [showLi, setShowLi] = useState(null);

  const [navLinks, setNavLinks] = useState(null);
  const localStorageData = getlocalStorageData();
  const [deletePopup, setDeletePopup] = useState(false);
  const [load, setLoad] = useState(false);
  const [errorPopup, setErrorPopup] = useState(false); // error popup
  const [errorHeader, setErrorHeader] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  // check if notification box is displayed for not

  function handleClickScrollCoach() {
    const element = document.getElementById("coach");

    if (location.pathname === "/athlete") {
      navigate("/");
      setTimeout(() => {
        const homeElement = document.getElementById("coach");
        homeElement.scrollIntoView({ behavior: "smooth" });
      }, 100);
    } else if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }

  function handleClickScrollAthele() {
    const element = document.getElementById("athlete");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }

  function handleClickScrollSupport() {
    // navigate("/viewCoaches");
    const element = document.getElementById("footer");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }

  function handleClickScrollAboutus() {
    const element = document.getElementById("about");

    // If use is  on the athlete page, navigate to home then scroll
    if (location.pathname === "/athlete") {
      navigate("/");
      setTimeout(() => {
        const homeElement = document.getElementById("about");
        if (homeElement) {
          homeElement.scrollIntoView({ behavior: "smooth" });
        }
      }, 100); // Delay to wait for the navigation to complete
    } else if (element) {
      // If already on the home page, just scroll smoothly to the "about" section
      element.scrollIntoView({ behavior: "smooth" });
    }
  }

  async function handleRemove() {
    if (load === true) {
      return;
    }
    setLoad(true);
    const res = await DeleteCoachForm();
    if (res) {
      setLoad(true);
      setDeletePopup(false);
      data.setOpen(false);
      window.location.href = "/";
    } else {
      setErrorHeader(ERROR_CONSTANTS.NetworkError.Heading);
      setErrorMessage(ERROR_CONSTANTS.NetworkError.Message);
      setErrorPopup(true);
      setLoad(false);
    }
  }

  const location = useLocation();

  useEffect(() => {
    // Check if the user is on the specified URL
    if (
      location.pathname === "/mybilling" ||
      location.pathname === "/viewaccount" ||
      location.pathname === "/viewcoachform" ||
      location.pathname === "/mycoachingplans" ||
      location.pathname === "/calender" ||
      location.pathname === "/coachform" ||
      location.pathname === "/editcoachform"
    ) {
      setShowLi(false);
      setNavLinks(true);
    } else {
      setShowLi(true);
      setNavLinks(false);
    }
  }, [location]);

  return (
    <>
      {errorPopup && (
        <>
          <div className="blur"></div>
          <ErrorPopup
            message={{
              heading: errorHeader,
              message: errorMessage,
            }}
            setErrorPopup={setErrorPopup}
          />
        </>
      )}
      {/* DELETE POPUP */}
      {deletePopup && (
        <>
          <div className="delete_blur"></div>
          <ActionPopup
            message={{
              heading: "Delete Form",
              message: `Do you really want to delete your coach account request ? This
          process cannot be undone`,
            }}
            setDeletePopup={setDeletePopup}
            handleRemove={handleRemove}
            load={load}
          />
        </>
      )}

      <div className="navbar fixed top-0 w-full bg-[var(--blue)] z-[40] h-[4rem] ">
        <div className=" flex justify-between items-center py-4 h-[4rem] ">
          <div className="  pl-8">
            <Logo />
          </div>
          <div className="navbar_li">
            {!navLinks ? (
              <>
                {showLi && (
                  <>
                    <li onClick={handleClickScrollCoach}>Become a Coach</li>
                    {/* <a href="/athlete.html">Go to Athlete Page</a> */}
                    <li>
                      <Link to="/athlete">For an Athlete</Link>
                    </li>
                    <li onClick={handleClickScrollAboutus}>About us</li>
                    <li onClick={handleClickScrollSupport}>Support</li>
                  </>
                )}
              </>
            ) : (
              <>
                <Link className="li" to="/">
                  Home
                </Link>
                <Link className="li" to="/mybilling">
                  My Billing
                </Link>
                <Link className="li" to="/viewaccount">
                  View Account
                </Link>


                {localStorageData.role === "Coach" ? (
                  <>
                    <Link className="li" to="/mycoachingplans">
                      My Coaching Plans
                    </Link>
                  </>
                ) : (
                  <></>
                )}
              </>
            )}
          </div>

          {data.isSignedIn ? (
            <>
              <ProfileBox setDeletePopup={setDeletePopup} />
            </>
          ) : (
            <>
              <div className="sign_button">
                <button
                  onClick={() => {
                    data.setOpen(!data.isOpen);
                    setSignUp(false);
                    console.log("hiy");
                  }}
                >
                  Sign In
                </button>
                <button
                  onClick={() => {
                    setSignUp(!isSignUp);
                    data.setOpen(false);
                  }}
                >
                  Sign Up
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default Nav;
