import axios from "axios";
import { Header } from "api/Signin";

// export async function getApis(urlPath) {
//   const header = Header();
//   return axios
//     .get(urlPath, {
//       headers: header,
//     })
//     .then((res) => {
//       // log specific url res only => path in string eg:- /files/get/2
//       if (urlPath.includes("change_path_to_log")) {
//         console.log(res);
//       }

//       if (res.data.status_code === 1) {
//         return res;
//       }
//     })
//     .catch((error) => {
//       console.log(error);
//       return error;
//     });
// }

export async function getApis(urlPath) {
  const header = Header();

  try {
    const res = await axios.get(urlPath, { headers: header });

    // log specific url res only => path in string eg:- /files/get/2
    if (urlPath.includes("change_path_to_log_response")) {
      console.log(res);
    }

    if (res.data.status_code === 1) {
      return res;
    }
  } catch (error) {
    console.log(error);
    return error;
  }
}
