import img1 from "assets/img/athlete_banner.png";
import img2 from "assets/img/cyclist-bike.jpg";
import img3 from "assets/img/man-with-red-cap-swimming-lake.jpg";

export const viewCoachesData = [
  {
    refId: "1",
    img: img1,
    Name: "Mehul j",
    about:
      "Hey, i am mehul your run Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy ....",

    coaching_since: "10",
    rating: "4",
    athlete_trained: "4",
    coaches: {
      run: "10k, 14k, 20k",
      swim: "1k, 5k",
    },

    certificate: [
      {
        name: "LEVEL 1 COACH",
        date: "May, 25, 2000",
      },
      {
        name: "IRONMAN",
        date: "Mar, 2, 2012",
      },
      {
        name: "REDROADSTER",
        date: "Nov, 2, 2002",
      },
    ],
  },
  {
    refId: "2",
    img: img2,
    Name: "Mehul Jorwekar",
    about:
      "Hey, i am mehul your run Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy ....",

    coaching_since: "10",
    rating: "4",
    athlete_trained: "10",
    coaches: {
      run: "10k, 14k, 20k",
      swim: "1k, 5k",
      cycle: "50k, 100k",
    },

    certificate: [
      {
        name: "LEVEL 1 COACH",
        date: "May, 25, 2000",
      },
      {
        name: "IRONMAN",
        date: "Mar, 2, 2012",
      },
      {
        name: "REDROADSTER",
        date: "Nov, 2, 2002",
      },
    ],
  },
  {
    refId: "3",
    img: img3,
    Name: "Mehul Jorwekar",
    about:
      "Hey, i am mehul your run Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy ....",

    coaching_since: "10",
    rating: "4",
    athlete_trained: "2",
    coaches: {
      run: "10k, 14k, 20k",
      swim: "1k, 5k",
      cycle: "50k, 100k",
    },

    certificate: [
      {
        name: "LEVEL 1 COACH",
        date: "May, 25, 2000",
      },
      {
        name: "IRONMAN",
        date: "Mar, 2, 2012",
      },
      {
        name: "REDROADSTER",
        date: "Nov, 2, 2002",
      },
    ],
  },
];
