import React, { useEffect, useState } from "react";
import { EditViewAccount } from "api/UserAccountController";
import ErrorPopup from "components/popups/ErrorPopup";
import { getlocalStorageData } from "hooks/basic";
import { useSelector, useDispatch } from "react-redux";
import { addViewAccountData } from "state/ViewAccountState";

import DateInput from "components/inputs/DateInput";

function ProfileBox(props) {
  const { setEditProfile, editAout, editSetting } = props;
  const [date, setDate] = useState(new Date());
  const dispatch = useDispatch();
  const data = useSelector((state) => state.viewAccount);

  const localStorageData = getlocalStorageData();
  // format date variable
  let new_date;
  const [dob, setDob] = useState("");
  // format date dd-mm-yyy
  // useEffect(() => {
  //   if (data.dob === undefined || null) {
  //     return;
  //   } else {
  //     const d = data.dob.split("-")[0];
  //     const m = data.dob.split("-")[1];
  //     const y = data.dob.split("-")[2];
  //     new_date = `${d}-${m}-${y}`;
  //     setDob(`${d}-${m}-${y}`);
  //   }
  // });

  //all  use states
  const [isType, setType] = useState(false);
  const [isEdit, setEdit] = useState(false);
  const [name, setName] = useState();
  const [tagline, setTagline] = useState();
  const [number, setNumber] = useState();
  const [athleteType, setAthleteType] = useState();
  const [errorPopup, setErrorPopup] = useState(false); // error popup
  const [errorHeader, setErrorHeader] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [gender, setGender] = useState();
  const [location, setLocation] = useState();
  const [editProfileDisable, setEditProfileDisable] = useState(false);

  // covert M,F,T into male, female, Trans

  useEffect(() => {
    if (data) {
      setName(data.name || "");
      setTagline(data.tagline || "");
      setNumber(data.mobile || "");
      setAthleteType(data.athlete_type || "");
      setGender(data.gender || "");
      setLocation(data.location || "");

      if (data.gender === "M") {
        setGender("Male");
      } else if (data.gender === "F") {
        setGender("Female");
      } else if (data.gender === "T") {
        setGender("Trans");
      }

      if (data.dob) {
        const dateParts = data.dob.split("-");
        if (dateParts.length === 3) {
          const [d, m, y] = dateParts;
          const new_date = `${d}-${m}-${y}`;
          setDob(new_date);
        } else {
          console.error("Invalid date format");
        }
      }
    }
  }, [data]);

  useEffect(() => {
    if (editAout || editSetting) {
      setEditProfileDisable(true);
    } else {
      setEditProfileDisable(false);
    }
  }, [editAout, editSetting]);

  // Function  when save is clicked
  async function handleSave() {
    if (name === "" || number === "" || gender === "") {
      setErrorHeader("Error");
      setErrorMessage("Fill up the fields");
      setErrorPopup(true);
    } else {
      const res = await EditViewAccount(
        name,
        number,
        athleteType,
        dob,
        gender,
        location,
        data.about
      );

      if (res === true) {
        const pData = {
          name: name,
          mobile: number,
          gender: gender,
          dob: dob,
          athlete_type: athleteType,
          location: location,
        };
        dispatch(addViewAccountData(pData));
        setEdit(false);
        setType(false);
        setEditProfile(false);
        // if (
        //   name === "" &&
        //   number === "" &&
        //   gender === "" &&
        //   dob === "" &&
        //   athleteType === ""
        // ) {
        //   const fetchData = async () => {
        //     try {
        //       const res = await getUserDetails();
        //       setData(res.data);
        //     } catch (error) {}
        //   };
        //   fetchData();
        // }
      } else {
        setErrorHeader("Error");
        setErrorMessage(`${res}`);
        setErrorPopup(true);
      }
    }
  }

  // date validation => when date is send to backend we are changing the format to dd-mm-yyyy
  function handleDate(e) {
    let date = e.target.value;
    const d = date.split("-")[2];
    const m = date.split("-")[1];
    const y = date.split("-")[0];
    let newDate = `${d}-${m}-${y}`;
    setDob(newDate);
  }

  // validation for date input field(can select current date - 18)
  const getMaxDate = () => {
    const today = new Date();
    const maxDate = new Date(today.setFullYear(today.getFullYear() - 18));
    return maxDate.toISOString().split("T")[0];
  };

  // NUMBER VALIDATION
  const NumberValidation = (e) => {
    const input = e.target.value;
    // Ensure input contains only digits and limit to 10 characters
    if (/^\d{0,10}$/.test(input)) {
      setNumber(input);
    }
  };

  return (
    <>
      {errorPopup && (
        <>
          <div className="blur"></div>
          <ErrorPopup
            message={{
              heading: errorHeader,
              message: errorMessage,
            }}
            setErrorPopup={setErrorPopup}
          />
        </>
      )}
      <div className={`mt-16 profile_box ${isEdit ? "edit_mode" : ""}`}>
        <div className="profile_box_heading_div">
          <h2>Profile</h2>
          {isEdit ? (
            <div className="flex">
              <p
                className="cancelButton"
                onClick={() => {
                  setEdit(false);
                  setEditProfile(false);
                }}
              >
                Cancel
              </p>
              <p className="saveButton" onClick={handleSave}>
                Save
              </p>
            </div>
          ) : (
            <button
              className="edit_button m-0 ml-[0.3rem]"
              disabled={editProfileDisable}
              onClick={() => {
                setEdit(true);
                setEditProfile(true);
              }}
            >
              Edit
            </button>
          )}
        </div>
        <div className="input_info_box_profile input_info_box">
          <div className="first_half">
            <p className="lable_tag">Name</p>
            {isEdit ? (
              <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            ) : (
              <p className="lable_value ">{data.name}</p>
            )}
          </div>
          <div className="second_half">
            <p className="lable_tag">Tagline</p>
            {isEdit ? (
              <input
                type="text"
                value={tagline}
                onChange={(e) => setTagline(e.target.value)}
              />
            ) : (
              <p className="lable_value ">
                {data.tagline === undefined ? (
                  <span>&ndash;</span>
                ) : (
                  `${data.tagline}`
                )}{" "}
              </p>
            )}
          </div>
        </div>
        <div className="input_info_box_profile input_info_box">
          <div className="first_half">
            <p className="lable_tag">Phone</p>
            {isEdit ? (
              <input
                type="number"
                pattern="\d{10}"
                maxLength={10}
                value={number}
                onChange={NumberValidation}
              />
            ) : (
              <p className="lable_value ">
                {data.mobile === undefined ? (
                  <span>&ndash;</span>
                ) : (
                  `${data.mobile}`
                )}
              </p>
            )}
          </div>

          <div className="second_half">
            <p className="lable_tag">Email</p>
            {isEdit ? (
              <p className="lable_value text-[var(--blue)]">{data.email}</p>
            ) : (
              <p className="lable_value ">
                {data.email === undefined ? (
                  <span>&ndash;</span>
                ) : (
                  `${data.email}`
                )}
              </p>
            )}
          </div>
        </div>
        <div className="input_info_box_profile input_info_box">
          <div className="first_half">
            <p className="lable_tag">Location</p>
            {isEdit ? (
              <input
                type="text"
                value={location}
                onChange={(e) => setLocation(e.target.value)}
              />
            ) : (
              <p className="lable_value ">
                {data.location === undefined ? (
                  <span>&ndash;</span>
                ) : (
                  `${data.location}`
                )}
              </p>
            )}
          </div>
          <div className="second_half">
            <p className="lable_tag">Birth date</p>

            {isEdit ? (
              <>
                {/* <input type="date" onChange={handleDate} max={getMaxDate()} /> */}
                <DateInput handleDate={handleDate} getMaxDate={getMaxDate} />
              </>
            ) : (
              <p className="lable_value ">
                {data.dob === undefined ? <span>&ndash;</span> : `${data.dob}`}
              </p>
            )}
          </div>
        </div>
        <div className="input_info_box_profile input_info_box">
          {localStorageData.role === "Athlete" && (
            <div className="first_half">
              <p className="lable_tag">Athlete Type</p>

              {isEdit ? (
                <select
                  className="select"
                  name="Athlete Type"
                  id=""
                  onChange={(e) => {
                    setAthleteType(e.target.value);
                  }}
                >
                  <option value="" disabled defaultValue hidden></option>
                  <option value="Runner">Runner</option>
                  <option value="Duathlete">Duathlete</option>
                  <option value="Triathlete">Triathlete</option>
                  <option value="Swimmer">Swimmer</option>
                  <option value="Biker">Biker</option>
                </select>
              ) : (
                <p className="lable_value ">
                  {data.athlete_type === undefined ? (
                    <span>&ndash;</span>
                  ) : (
                    `${data.athlete_type}`
                  )}
                </p>
              )}
            </div>
          )}

          <div className="second_half">
            <p className="lable_tag">Gender</p>
            {isEdit ? (
              <div className=" mydict_viewAccount_gender">
                <div className="flex flex-wrap mt-2 justify-start">
                  <label>
                    <input
                      type="radio"
                      name="radio"
                      value={gender}
                      onChange={() => setGender("Male")}
                    />
                    <span className={gender === "Male" ? "genderColors" : ""}>
                      Male
                    </span>
                  </label>
                  <label>
                    <input
                      type="radio"
                      name="radio"
                      value={gender}
                      onChange={() => setGender("Female")}
                    />
                    <span className={gender === "Female" ? "genderColors" : ""}>
                      Female
                    </span>
                  </label>
                  <label>
                    <input
                      type="radio"
                      name="radio"
                      value={gender}
                      onChange={() => setGender("Trans")}
                    />
                    <span className={gender === "Trans" ? "genderColors" : ""}>
                      Trans
                    </span>
                  </label>
                </div>
              </div>
            ) : (
              <p className="lable_value ">
                {data.gender === undefined ? <span>&ndash;</span> : `${gender}`}
              </p>
            )}
          </div>
        </div>
        {/* <div className="input_info_box_profile">
        <div className="first_half">
          
        </div>
      </div> */}
      </div>
    </>
  );
}

export default ProfileBox;
