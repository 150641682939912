import axios from "axios";
import {API_URL_CONSTANTS} from 'utility/constants/apiUrlConstants'
import {Header} from 'api/Signin'

export async function getCoachingPlans() {
  const header = Header();

  return axios
    .get(API_URL_CONSTANTS.GET_COACHING_PLANS, {
      headers: header,
    })
    .then((res) => {
      if (res.data.status_code === 1) {
        return res;
      } else {
        return false;
      }
    })
    .catch((error) => {
      return error;
    });
}

export async function AddCoachingPlans(
  planName,
  planDetails,
  planPrice,
  isTax
) {
  const header = Header();

  return axios
    .post(
      API_URL_CONSTANTS.ADD_COACHING_PLANS,
      {
        plan_name: planName,
        plan_charge: planPrice,
        description: planDetails,
        is_tax_included: isTax,
      },
      {
        headers: header,
      }
    )
    .then((res) => {
      if (res.data.status_code === 1) {
        return true;
      } else {
        return { res: false, message: res.data.message };
      }
    })
    .catch((error) => {
      return error;
    });
}

export async function UpdateCoachingPlan(
  planName,
  planDetails,
  planPrice,
  isTax,
  planId
) {
  const header = Header();
  return axios
    .post(
      API_URL_CONSTANTS.UPDATE_COACHING_PLANS,
      {
        plan_name: planName,
        plan_charge: planPrice,
        description: planDetails,
        is_tax_included: isTax,
        plan_id: planId,
      },
      {
        headers: header,
      }
    )
    .then((res) => {
      if (res.data.status_code === 1) {
        return true;
      } else {
        return { res: false, message: res.data.message };
      }
    })
    .catch((error) => {
      return error;
    });
}

export async function DeleteCoahingPlan(plan_id) {
  const header = Header();

  return axios
    .post(
      API_URL_CONSTANTS.DELETE_COACHING_PLANS + plan_id,
      {},
      {
        headers: header,
      }
    )
    .then((res) => {
      if (res.data.status_code === 1) {
        return true;
      }
    })
    .catch((error) => {
      return error;
    });
}
