import React, { useContext, useEffect, useState } from "react";
import PhoneFormStep5 from "./PhoneFormStep5";
import RightArrow from "assets/icons/right-arrow.png";
import { useNavigate } from "react-router-dom";
import { SubmitCoachFormData } from "utility/constants/useContext";
import PhoneFormStep3 from "./PhoneFormStep3";
import BackArrow from "assets/icons/back.png";
import Nav from "components/navbar/Nav";
import PhoneNavBar from "components/navbar/PhoneNavBar";
import PopUpLoginP from "components/signin/PhonePopUpLogin";
import { updateForm } from "state/SetCoachFormData";

function PhoneFormStep4() {
  const Props = useContext(SubmitCoachFormData);
  const [isYes, setYes] = useState("");
  const [validation, setValidation] = useState(false);
  const [nextClicked, setNextClicked] = useState(false);
  const [popper, setPopper] = useState(false);
  const navigate = useNavigate();
  const [backClicked, setBackClicked] = useState(false);

  // scroll to top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const handleBackClick = () => {
    setBackClicked(true);
  };
  useEffect(() => {
    if (Props.formState.isRun === "yes" || Props.formState.isRun === "no") {
      setValidation(true);
    } else {
      setValidation(false);
    }
  });
  const handleCheckboxChange = (value) => {
    // If the value is already selected, remove it from the array
    const updatedSelectedDistancesRun =
      Props.formState.selectedDistancesRun.includes(value)
        ? Props.formState.selectedDistancesRun.filter((item) => item !== value)
        : [...Props.formState.selectedDistancesRun, value];

    Props.dispatch(
      updateForm({ selectedDistancesRun: updatedSelectedDistancesRun })
    );
  };

  return (
    <div>
      {backClicked ? (
        <PhoneFormStep3 />
      ) : (
        <>
          {nextClicked && validation ? (
            <PhoneFormStep5 />
          ) : (
            <>
              <div className="coach_form">
                <Nav />
                <PhoneNavBar />
                <PopUpLoginP />
                <div className="form_section">
                  <form action="" method="post" className="mt-16 m-0">
                    <div className="phone_form_step4 mt-16">
                      <div className="question_1">
                        <h4>Do you train athletes for running ?</h4>
                        <div className="yes_no_div swim">
                          <label>
                            <input
                              type="radio"
                              name="radio"
                              onChange={() =>
                                Props.dispatch(updateForm({ isRun: "yes" }))
                              }
                              checked={Props.formState.isRun === "yes"}
                            />
                            <span>Yes</span>
                          </label>

                          <label>
                            <input
                              type="radio"
                              name="radio"
                              onChange={() => {
                                Props.dispatch(
                                  updateForm({ selectedDistancesRun: [] })
                                );
                                Props.dispatch(updateForm({ isRun: "no" }));
                              }}
                              checked={Props.formState.isRun === "no"}
                            />
                            <span>No</span>
                          </label>
                        </div>
                      </div>

                      {Props.formState.isRun === "yes" ? (
                        <>
                          <div className="question_2">
                            <h4>Select distances</h4>
                            <div className="yes_no_div swim pb-[4rem]">
                              <label>
                                <input
                                  type="checkbox"
                                  name="radio"
                                  onChange={() => handleCheckboxChange("5k")}
                                  checked={Props.formState.selectedDistancesRun.includes(
                                    "5k"
                                  )}
                                />
                                <span>5k</span>
                              </label>

                              <label>
                                <input
                                  type="checkbox"
                                  name="radio"
                                  id=""
                                  onChange={() => handleCheckboxChange("7k")}
                                  checked={Props.formState.selectedDistancesRun.includes(
                                    "7k"
                                  )}
                                />
                                <span>7k</span>
                              </label>

                              <label>
                                <input
                                  type="checkbox"
                                  name="radio"
                                  id=""
                                  onChange={() => handleCheckboxChange("10k")}
                                  checked={Props.formState.selectedDistancesRun.includes(
                                    "10k"
                                  )}
                                />
                                <span>10k</span>
                              </label>

                              <label>
                                <input
                                  type="checkbox"
                                  name="radio"
                                  id=""
                                  onChange={() => handleCheckboxChange("16k")}
                                  checked={Props.formState.selectedDistancesRun.includes(
                                    "16k"
                                  )}
                                />
                                <span>16k</span>
                              </label>

                              <label>
                                <input
                                  type="checkbox"
                                  name="radio"
                                  id=""
                                  onChange={() => handleCheckboxChange("21k")}
                                  checked={Props.formState.selectedDistancesRun.includes(
                                    "21k"
                                  )}
                                />
                                <span>21k</span>
                              </label>

                              <label>
                                <input
                                  type="checkbox"
                                  name="radio"
                                  id=""
                                  onChange={() => handleCheckboxChange("42k")}
                                  checked={Props.formState.selectedDistancesRun.includes(
                                    "42k"
                                  )}
                                />
                                <span>42k</span>
                              </label>
                            </div>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                      <div className="Phone_next_steps_btn">
                        {popper && (
                          <div className="popper absolute bottom-11 right-0">
                            <p>Fill up the fileds</p>
                          </div>
                        )}
                        <p
                          className="statusBarPhone"
                          onClick={() => {
                            if (validation === true) {
                              setNextClicked(true);
                              setPopper(false);
                            } else {
                              if (validation === false) {
                                setPopper(true);
                              }
                            }
                          }}
                        >
                          <div className="flex justify-between">
                            <p
                              className="mr-[10px] mt-[10px] cancelButton"
                              onClick={handleBackClick}
                            >
                              <div className=" flex items-center">
                                <img
                                  src={BackArrow}
                                  alt=""
                                  className="next_img mr-1 ml-[-3px]"
                                />
                                Back
                              </div>
                            </p>
                            <div className="coach_cancel_Div flex">
                              <p
                                className="mr-[10px] mt-[10px] cancelButton"
                                onClick={() => {
                                  navigate("/");
                                }}
                              >
                                Cancel
                              </p>
                              <div className="nextButton">
                                Next{" "}
                                <img
                                  src={RightArrow}
                                  alt=""
                                  className="next_img mt-[6px]"
                                />
                              </div>
                            </div>
                          </div>
                        </p>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
}

export default PhoneFormStep4;
