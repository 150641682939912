import React, { useState, useEffect, useContext } from "react";
import EmailIcon from "assets/icons/drafts.png";
import PhoneIcon from "assets/icons/call.png";
import LocationIcon from "assets/icons/add_location.png";
import Tag from "assets/icons/Tag.png";
import User from "assets/icons/person.png";
import { updateForm } from "state/SetCoachFormData";
import { SubmitCoachFormData } from "utility/constants/useContext";
function PhoneFormStep1(props) {
  const { isValidEmail, setIsValidEmail } = props;

  const Props = useContext(SubmitCoachFormData);

  useEffect(() => {
    if (Props.gender === "M") {
      Props.setGender("Male");
    } else if (Props.gender === "F") {
      Props.setGender("Female");
    } else if (Props.gender === "T") {
      Props.setGender("Trans");
    }
  }, [Props.gender]);

  // scroll to top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Email validation logic

  const handleEmail = (event) => {
    const { value } = event.target;
    Props.setEmail(value);
    // Validate email using regular expression
    const isValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
    setIsValidEmail(isValid);
  };

  //Phone Validation Logic
  const [isValidNumber, setIsValidNumber] = useState(true);
  const handleNumber = (event) => {
    const { value } = event.target;

    // Check if the value is numeric and has a length of 10 or fewer digits
    if (/^\d{0,10}$/.test(value)) {
      Props.dispatch(updateForm({ number: value }));

      // Validate if the number is exactly 10 digits long
      if (value.length === 10) {
        setIsValidNumber(true);
      } else {
        setIsValidNumber(false);
      }
    }
  };

  return (
    <>
      <div className="w-[90%]  mx-auto pt-16 pb-16 phone_step1 ">
        <div className="">
          <div className="location">
            <label htmlFor="input" className="text">
              Name<sup className="text-[var(--red)]"> *</sup>
            </label>
            <div className="flex items-center">
              <img src={User} className="mr-4" alt="" />
              <input
                type="text"
                placeholder="Name"
                name="input"
                className="input"
                value={Props.formState.name}
                onChange={(e) =>
                  Props.dispatch(updateForm({ name: e.target.value }))
                }
                required
              />
            </div>
          </div>

          <div className="location">
            <label htmlFor="input" className="text">
              Tagline<sup className="text-[var(--red)]"> *</sup>
            </label>
            <div className="flex items-center">
              <img src={Tag} className="mr-4" alt="" />
              <input
                type="text"
                placeholder=" Tagline "
                name="input"
                className="input"
                value={Props.formState.tagline}
                onChange={(e) =>
                  Props.dispatch(updateForm({ tagline: e.target.value }))
                }
                required
              />
            </div>
          </div>
          <div className="email  ">
            <label htmlFor="input" className="email z-10">
              Email
              <sup className="text-[var(--red)] top-[-1rem] ">*</sup>
            </label>
            <div className="flex items-center">
              <img src={EmailIcon} className="mr-4" alt="" />
              <input
                type="email"
                placeholder=""
                name="email"
                value={Props.email}
                className="input"
                disabled={true}
                onChange={handleEmail}
                required
              />
              {isValidEmail ? (
                <></>
              ) : (
                <div>
                  {" "}
                  <p className="text-[var(--red)] text-right mr-[15%]">
                    invalid email
                  </p>
                </div>
              )}
            </div>
          </div>

          <div className="number">
            <label htmlFor="input" className="text">
              Phone Number <sup className="text-[var(--red)]"> *</sup>
            </label>
            <div className="flex items-center">
              <img src={PhoneIcon} className="mr-4" alt="" />
              <input
                type="text"
                placeholder=""
                name="number"
                className="input"
                value={Props.formState.number}
                maxLength={10}
                onChange={handleNumber}
                required
              />
            </div>
            {isValidNumber ? (
              <></>
            ) : (
              <div>
                {" "}
                <p className="text-[var(--red)] text-right absolute right-[15%]">
                  Invalid Number
                </p>
              </div>
            )}
          </div>

          <div className="location">
            <label htmlFor="input" className="text">
              Location<sup className="text-[var(--red)]"> *</sup>
            </label>
            <div className="flex items-center">
              <img src={LocationIcon} className="mr-4" alt="" />
              <input
                type="text"
                placeholder=" City, State, Country "
                name="input"
                className="input"
                value={Props.formState.location}
                onChange={(e) =>
                  Props.dispatch(updateForm({ location: e.target.value }))
                }
                required
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PhoneFormStep1;
