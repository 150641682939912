import React, { useState } from "react";
import Certificed from "assets/icons/certified.svg";
import { viewCoachesData } from "../data";
import FourStar from "assets/icons/four_star.svg";
import Arow from "assets/icons/arow_more_details.svg";

function ViewCoachCard() {
  const [moreDetails, setMoreDetails] = useState({});
  //check if clicked  SIGN UP button (sign up popup will come)

  // Function to toggle more details for a specific card
  const handleToggleDetails = (refId) => {
    setMoreDetails((prevDetails) => ({
      ...prevDetails,
      [refId]: !prevDetails[refId], // Toggle the current state of the specific card
    }));
  };
  return (
    <>
      <div className="coach_card_desktop ">
        {viewCoachesData.map((data) => (
          <div
            key={data.refId}
            className="bg-[var(--white)] w-[95%] mx-auto my-4 shadow p-2 "
          >
            {/* Certificate div Start*/}
            <div className="flex ">
              {data.certificate.map((items) => (
                <div className="flex flex-col pl-[1rem]">
                  <div className="flex items-center">
                    <img className="h-[1rem]" src={Certificed} alt="" />
                    <h6 className="text-[var(--blue)] font-normal">
                      {items.name}
                    </h6>
                  </div>
                  <div>
                    <p className="text-[0.6rem] mt-[-0.2rem]">({items.date})</p>
                  </div>
                </div>
              ))}
            </div>
            {/* Certificate div End*/}

            {/* card body start */}
            <div className="flex  pt-4 px-4">
              <div className="flex flex-col items-start py-2 w-[20%]">
                <img
                  className="rounded-[50%] w-[8rem] h-[8rem] object-cover"
                  src={data.img}
                  alt=""
                />
                <div className="flex flex-col ">
                  <h6 className="text-[1.3rem] text-[var(--blue)]  font-normal pt-2">
                    {data.Name}
                  </h6>

                  <span className="text-[.7rem]">
                    ( Coaching Since{data.coaching_since} )
                  </span>
                  <img className="pt-1 w-[5rem]" src={FourStar} alt="" />
                  <span className="text-[.7rem]">
                    ( Athlete trained {data.athlete_trained} )
                  </span>
                </div>
              </div>
              <div className="flex flex-col p-2 w-[80%]">
                <p className="text-[1.1rem] text-[var(--light-blue)] leading-7 tracking-wide">
                  {data.about}
                </p>
                <div className="flex pt-[2rem]">
                  <h6 className="text-[var(--blue)] font-normal">Coaches </h6>
                  <ul className=" viewCoah_li">
                    {moreDetails[data.refId] ? (
                      <>
                        {" "}
                        {data.coaches.run && (
                          <>
                            <li className=" ">Run: {data.coaches.run}</li>
                          </>
                        )}
                        {data.coaches.swim && (
                          <>
                            <li className=" ">Siwm: {data.coaches.swim}</li>
                          </>
                        )}
                        {data.coaches.cycle && (
                          <>
                            <li className=" ">Cycle: {data.coaches.cycle}</li>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        {" "}
                        {data.coaches.run && (
                          <>
                            <li className=" ">Run</li>
                          </>
                        )}
                        {data.coaches.swim && (
                          <>
                            <li className=" ">Siwm</li>
                          </>
                        )}
                        {data.coaches.cycle && (
                          <>
                            <li className=" ">Cycle</li>
                          </>
                        )}
                      </>
                    )}

                    <div
                      className="flex items-center pl-4 cursor-pointer text-[var(--green)] text-[13px] underline"
                      onClick={() => handleToggleDetails(data.refId)}
                    >
                      {moreDetails[data.refId] ? (
                        <>
                          <img className="rotate-180 pl-2" src={Arow} alt="" />{" "}
                          <span className="">Less Details</span>
                        </>
                      ) : (
                        <>
                          <span className=" ">More Details</span>
                          <img className="pl-2" src={Arow} alt="" />
                        </>
                      )}
                    </div>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

export default ViewCoachCard;
