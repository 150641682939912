import React, { useEffect } from "react";
import { API_URL_CONSTANTS } from "utility/constants/apiUrlConstants";
import ErrorPopup from "components/popups/ErrorPopup";
import { useSelector } from "react-redux";
import Edit from "assets/icons/edit.png";
import { usePhotoHandlers } from "utility/utility_functions/profilePhotos";
import { useCoverHandlers } from "utility/utility_functions/coverPhoto";

function EditPhoto(props) {
  const { gender, CoverImgSrc, profileImgSrc, handleCover, handleProfile } =
    props;

  const {
    handleProfilePhoto,
    profilePhotoURL,
    errorPopup: profileErrorPopup,
    errorMessage: profileErrorMessage,
    errorHeader: profileErrorHeader,
    setErrorPopup: setProfileErrorPopup,
  } = usePhotoHandlers(handleProfile);

  const {
    photoURL,
    handleFileChange,
    errorPopup: coverErrorPopup,
    errorMessage: coverErrorMessage,
    errorHeader: coverErrorHeader,
    setErrorPopup: setCoverErrorPopup,
  } = useCoverHandlers(handleCover);

  return (
    <>
      {(profileErrorPopup || coverErrorPopup) && (
        <>
          <div className="blur"></div>
          <ErrorPopup
            message={{
              heading: profileErrorPopup
                ? profileErrorHeader
                : coverErrorHeader,
              message: profileErrorPopup
                ? profileErrorMessage
                : coverErrorMessage,
            }}
            setErrorPopup={
              profileErrorPopup ? setProfileErrorPopup : setCoverErrorPopup
            }
          />
        </>
      )}
      <div className="cover_photo cover_photo_edit mt-16">
        <>
          <img
            src={
              photoURL
                ? `${API_URL_CONSTANTS.DOMAIN_NAME}${photoURL}`
                : `${API_URL_CONSTANTS.DOMAIN_NAME}${CoverImgSrc}`
            }
            className="cover_photo_img"
          />
          <div className="edit_logo_cover">
            <div className="edit_cricle">
              <input
                id="file-input"
                type="file"
                accept="image/*"
                onChange={(e) => handleFileChange(e, "edit", "editCoach")}
                className="hidden"
              />
              <label className="c" htmlFor="file-input">
                <img src={Edit} alt="" />
              </label>
            </div>
          </div>
        </>
        <div className="profile_input_box">
          <div
            className={`profile_photo profile_photo_edit ${
              gender === "Female" || gender === "F"
                ? "profile_photo"
                : gender === "Male" || gender === "M"
                ? "profile_photo_man"
                : "profile_photo_unknown"
            }`}
          >
            <img
              src={
                profilePhotoURL
                  ? `${API_URL_CONSTANTS.DOMAIN_NAME}${profilePhotoURL}`
                  : `${API_URL_CONSTANTS.DOMAIN_NAME}${profileImgSrc}`
              }
              className="profile_photo_img"
            />
            <div className="edit_logo_profile">
              <input
                type="file"
                accept="image/*"
                id="profilePhoto"
                onChange={(e) => handleProfilePhoto(e, "edit", "editCoach")}
                className="hidden"
              />
              <label htmlFor="profilePhoto">
                <img src={Edit} alt="" />
              </label>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EditPhoto;
