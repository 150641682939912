import React from "react";
import Certificed from "assets/icons/certified.svg";
import { viewCoachesData } from "../data";
import FourStar from "assets/icons/four_star.svg";

function ViewCoachCardPhone() {
  return (
    <div>
      <div className="coach_card_phone ">
        {viewCoachesData.map((data) => (
          <div
            key={data.refId}
            className="bg-[var(--white)] w-[95%] mx-auto my-4 shadow p-2 "
          >
            {/* Certificate div Start*/}
            <div className="flex ">
              {/* {data.certificate.map((items) => ( */}
              <div className="flex flex-col pl-[1rem]">
                <div className="flex items-center">
                  <img className="h-[1rem]" src={Certificed} alt="" />
                  <h6 className="text-[var(--blue)] font-normal">
                    {data.certificate[0].name}
                  </h6>
                </div>
                <div>
                  <p className="text-[0.6rem] mt-[-0.2rem]">
                    ({data.certificate[0].date})
                  </p>
                </div>
              </div>
              <div className="flex flex-col pl-[1rem]">
                <div className="flex items-center">
                  <img className="h-[1rem]" src={Certificed} alt="" />
                  <h6 className="text-[var(--blue)] font-normal">
                    {data.certificate[1].name}
                  </h6>
                </div>
                <div>
                  <p className="text-[0.6rem] mt-[-0.2rem]">
                    ({data.certificate[1].date})
                  </p>
                </div>
              </div>
              {/* ))} */}
            </div>
            {/* Certificate div End*/}

            {/* card body start */}
            <div className="flex flex-col  pt-4 px-4">
              <div className="flex  items-start py-2 ">
                <img
                  className="rounded-[50%] w-[8rem] h-[8rem] object-cover"
                  src={data.img}
                  alt=""
                />
                <div className="flex flex-col pl-[1rem] justify-center">
                  <h6 className="text-[1.3rem] text-[var(--blue)]  font-normal pt-2">
                    {data.Name}
                  </h6>

                  <span className="text-[.7rem]">
                    ( Coaching Since{data.coaching_since} )
                  </span>
                  <img className="pt-1 w-[5rem]" src={FourStar} alt="" />
                  <span className="text-[.7rem]">
                    ( Athlete trained {data.athlete_trained} )
                  </span>
                </div>
              </div>
              <div className="flex flex-col p-2">
                <p className="text-[1.1rem] text-[var(--light-blue)] leading-7 tracking-wide">
                  {data.about}
                </p>
                <div className="flex flex-col pt-[2rem]">
                  <h6
                    className="text-[var(--blue)] text-[1.2rem] font-normal pb-2
                  "
                  >
                    Coaches :
                  </h6>
                  <ul
                    className=" viewCoah_li flex flex-col text-[1.2rem]
                  "
                  >
                    {data.coaches.run && <li>Run: {data.coaches.run}</li>}
                    {data.coaches.swim && <li>Swim: {data.coaches.swim} </li>}
                    {data.coaches.cycle && (
                      <li>Cycle: {data.coaches.cycle} </li>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      ;
    </div>
  );
}

export default ViewCoachCardPhone;
