import { getUserDetails } from "api/UserAccountController";
import { SwitchAccount } from "api/CoachFormController";
import { SetDataInlocalStorage } from "api/CoachFormController";
import { logout } from "api/Signin";
import { getlocalStorageData } from "hooks/basic";

const localStorageData = getlocalStorageData();
export async function SwitchAthleteToCoach(
  coachSignUpStatus,
  setCoachSignupStatus,
  verifiedClicked,
  RememberMe
) {
  if (coachSignUpStatus === "Verified" && verifiedClicked === true) {
    let ref_id;
    if (localStorageData.role === "Athlete") {
      const fetchProfileData = async () => {
        try {
          const res = await getUserDetails();
          if (res.data.profiles.length > 0) {
            ref_id = res.data.profiles[0].ref;
            if (ref_id) {
              // API code for Switch account
              const fetchData = async () => {
                try {
                  const res = await SwitchAccount(ref_id);
                  if (res) {
                    logout();
                    SetDataInlocalStorage(res, RememberMe);
                    const r =
                      localStorage.getItem("role") ??
                      sessionStorage.getItem("role");
                    setCoachSignupStatus("View Profile");
                  }
                } catch (error) {
                  return error;
                }
              };
              // calling switch account
              fetchData();
            }
          }
        } catch (error) {
          return error;
        }
      };
      //calling get user details
      fetchProfileData();
    }
  } else {
    return;
  }
}
