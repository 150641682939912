import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  certificates: [],
  name: "",
  tagline: "",
  email: "",
  number: "",
  location: "",
  gender: "",
  aboutYourSelf: "",
  getCover: "",
  getProfile: "",
  getVideo: "",
  coverPhotoPath: "",
  profilePhotoPath: "",
  videoPath: "",
  selectedDistancesRun: [],
  isRun: "",
  selectedDistancesSwim: [],
  isSiwm: "",
  selectedDistancesCycle: [],
  isCycle: "",
  selectedDistancesTriathlon: [],
  isTri: "",
};

const setCoachFormSlice = createSlice({
  name: "SetCoachFormData",
  initialState,
  reducers: {
    updateForm: (state, action) => {
      return { ...state, ...action.payload };
    },

    updateCertificates: (state, action) => {
      state.certificates = action.payload;
    },
  },
});

export const { updateForm, updateCertificates } = setCoachFormSlice.actions;

export default setCoachFormSlice.reducer;
