import axios from "axios";
import { API_URL_CONSTANTS } from "utility/constants/apiUrlConstants.js";
import { Header } from "api/Signin";

export async function getUserDetails() {
  const header = Header();
  return axios
    .get(API_URL_CONSTANTS.GET_USER_DETAILS, {
      headers: header,
    })
    .then((res) => {
      if (res.status === 200) {
        return res;
      }
    })
    .catch((error) => {
      throw error;
    });
}

export async function GetMyCoachingDetails() {
  const header = Header();
  return axios
    .get(API_URL_CONSTANTS.MY_COACHING_DETAILS, {
      headers: header,
    })
    .then((res) => {
      if (res.status === 200) {
        return res;
      }
    })
    .catch((error) => {
      return error;
    });
}

// export async function getCoverImg() {
//   const header = Header();
//   return axios
//     .get(AppConstants.GET_COVER_IMG, {
//       headers: header,
//     })
//     .then((res) => {
//       if (res.status === 200) {
//         return res;
//       }
//     })
//     .catch((error) => {
//       return error;
//     });
// }

// export async function getProfileImg() {
//   const header = Header();
//   return axios
//     .get(AppConstants.GET_PROFILE_IMG, {
//       headers: header,
//     })
//     .then((res) => {
//       if (res.status === 200) {
//         return res;
//       }
//     })
//     .catch((error) => {
//       return error;
//     });
// }

export async function EditViewAccount(
  name,
  mobile,
  athleteType,
  dob,
  gender,
  location,
  about
) {
  const header = Header();

  return axios
    .post(
      API_URL_CONSTANTS.VIEW_ACCOUNT_EDIT,
      {
        name: name,
        gender: gender,
        dob: dob,
        mobile: mobile,
        athlete_type: athleteType,
        about: about,
        location: location,
      },
      {
        headers: header,
      }
    )
    .then((res) => {
      if (res.data.status_code === 1) {
        return true;
      }
    })
    .catch((error) => {
      return error;
    });
}

export async function EditPoolength(pool_length) {
  const header = Header();

  return axios
    .post(
      API_URL_CONSTANTS.POOL_LENGTH_EDIT,
      {
        pool_length: pool_length,
      },
      {
        headers: header,
      }
    )
    .then((res) => {
      if (res.data.status_code === 1) {
        return true;
      }
    })
    .catch((error) => {
      return error;
    });
}

export async function ConnectToGarmin() {
  const header = Header();

  return axios
    .post(API_URL_CONSTANTS.GARMIN_CONNECTIONS, null, {
      headers: header,
    })
    .then((res) => {
      if (res.status === 200) {
        return res;
      }
    })
    .catch((error) => {
      return error;
    });
}
