import React, { useContext } from "react";
import { Link } from "react-router-dom";
import Close from "assets/icons/close_notification.svg";
import Exclamation from "assets/icons/exclamation.svg";
import { AppProps } from "utility/constants/useContext";

function PhoneNotification(props) {
  const { bell, setBell, setDeletePopup } = props;

  const AppData = useContext(AppProps);

  const pendingVerfication = [
    "Pending Verification",
    "Your coach account verification is pending",
  ];

  const needAction = [
    "Coach Request Submitted",
    "We need more information to proceed ahead",
  ];
  return (
    <>
      <div className={`notification ${bell ? "notification_visible" : ""}`}>
        <div className="flex justify-end pt-1 pr-1">
        </div>
        <div className=" flex items-start justify-center pb-[0.7rem] px-[.5rem] bg-[var(-white)] text-[var(--black)] z-10  duration-500">
          <div>

            <div className=" rounded-[50%] bg-[var(--dark-yellow)] mt-2">
              <img src={Exclamation} alt=""/>
            </div>
            
          </div>
          <div className=" pl-[0.7rem]">
            {AppData.coachSignUpStatus === "Pending verfication" && (
              <>
                <p className="!m-0 text-[var(--black)] font-normal text-[1.2rem] flex">
                  {pendingVerfication[0]}
                  <img onClick={() => setBell(false)} src={Close} alt="" className="ml-[50px]" />

                </p>
                <p className="!m-0 text-[var(--black) ]">
                  {pendingVerfication[1]}
                  
                </p>

                <p>
                  <span className="click_here_btn">
                    <Link className="text-[var(--white)] pr-[0.2rem]" to="/viewcoachform">
                      Click here
                    </Link>
                  </span>
                  to view/edit your request.
                </p>
                <p className="!mt-1">
                  <span
                    className="click_here_btn bg-[var(--dark-red)] "
                    onClick={() => setDeletePopup(true)}
                  >
                    Delete
                  </span>
                  Delete your coach account request
                </p>
              </>
            )}

            {AppData.coachSignUpStatus === "Need Action" && (
              <>
              <div className="flex">
              <p className="!m-0 text-[var(--black)] font-normal text-[1.2rem]"> 
                  {needAction[0]}
                </p>
                <img onClick={() => setBell(false)} src={Close} alt="" className="ml-[50px]" />
              </div>


                <p className="!m-0 text-[var(--black) ]">{needAction[1]}</p>
                <p className=" mt-1">
                  <span className="click_here_btn pr-[0.2rem]">
                    <Link className="text-[var(--white)]" to="/editcoachform">
                      Click here
                    </Link>
                  </span>
                  Modify Your Coach Request.
                </p>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default PhoneNotification;
