import React from "react";
import { API_URL_CONSTANTS } from "utility/constants/apiUrlConstants";
import Edit from "assets/icons/edit.png";
import ErrorPopup from "components/popups/ErrorPopup";
import { useVideoHandlers } from "utility/utility_functions/videos";
function EditVideo(props) {
  const { videoUrl, setVideoPath } = props;
  const {
    handleFileChange,
    videoFileURL,
    errorHeader,
    errorMessage,
    errorPopup,
    setErrorPopup,
  } = useVideoHandlers();

  return (
    <>
      {errorPopup && (
        <>
          <div className="blur"></div>
          <ErrorPopup
            message={{
              heading: errorHeader,
              message: errorMessage,
            }}
            setErrorPopup={setErrorPopup}
          />
        </>
      )}
      <div
        className="profile_box mt-16 mb-0"
      >
        <div className="profile_box_heading_div">
          <h2>Video</h2>
        </div>
        <div className="input_info_box">
          <div className="video_box">
            <div className="video_div m-0" >
              <video
                id="video"
                src={
                  videoFileURL
                    ? videoFileURL
                    : `${API_URL_CONSTANTS.DOMAIN_NAME}${videoUrl}`
                }
                controls
              ></video>
              <div className="">
                <div className="edit_cricle">
                  <input
                    id="video-input"
                    type="file"
                    accept="video/*"
                    onChange={(event) => handleFileChange(event, setVideoPath)} 
                    className="hidden"
                  />
                  <label className="c" htmlFor="video-input">
                    <img src={Edit} alt="" />
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EditVideo;
