import React, { useState, useEffect, useContext } from "react";
import AddCertificate from "./AddCertificate";
import EmailIcon from "assets/icons/drafts.png";
import PhoneIcon from "assets/icons/call.png";
import LocationIcon from "assets/icons/add_location.png";
import { SubmitCoachFormData } from "utility/constants/useContext";
import { updateForm } from "state/SetCoachFormData.js";

function FormHeaderLeft() {
  const Props = useContext(SubmitCoachFormData);

  function setValues(e) {
    Props.dispatch(updateForm({ [e.target.name]: e.target.value }));
  }

  useEffect(() => {
    if (Props.gender === "M") {
      Props.setGender("Male");
    } else if (Props.gender === "F") {
      Props.setGender("Female");
    } else if (Props.gender === "T") {
      Props.setGender("Trans");
    }
  }, [Props.gender]);
  const Email =
    localStorage.getItem("email") ?? sessionStorage.getItem("email");

  // Email validation logic
  const [isValidEmail, setIsValidEmail] = useState(true);
  const handleEmail = (event) => {
    const { value } = event.target;
    Props.setEmail(value);

    // Validate email using regular expression
    const isValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
    setIsValidEmail(isValid);
  };

  //Phone Validation Logic
  // const [isValidNumber, setIsValidNumber] = useState(true);

  const handleNumber = (event) => {
    const { value } = event.target;

    // Check if the value is numeric and has a length of 10 or fewer digits
    if (/^\d{0,10}$/.test(value)) {
      Props.dispatch(updateForm({ number: value }));
      // Validate if the number is exactly 10 digits long
      if (value.length === 10) {
        Props.setIsValidNumber(true);
      } else {
        Props.setIsValidNumber(false);
      }
    }
  };

  return (
    <div className="w-1/2 py-0 px-8 form_header_left formHeader mb-4 mt-[4rem] ">
      <div className="email mt-1 flex flex-col ">
        <label htmlFor="input" className="email !top-[0.6rem]">
          Email
          <sup className="text-[var(--red)] !top-[-0.9rem]">*</sup>
        </label>

        <div className="input_line flex items-center">
          <img src={EmailIcon} alt="" className="w-5 h-5 mr-4" />
          <input
            type="email"
            placeholder=""
            name="email"
            value={Email}
            disabled={true}
            className="input !py-[0.6rem] !px-[0.7rem]"
            onChange={handleEmail}
            required
          />
          {isValidEmail ? (
            <></>
          ) : (
            <p className="text-[var(--red)] text-right mr-[14%] font-thin text-[.7rem]">
              Invalid Email
            </p>
          )}
        </div>
      </div>

      <div className="number mt-1">
        <label htmlFor="input" className="text !top-[0.6rem]">
          Phone Number <sup className="text-[var(--red)]"> *</sup>
        </label>
        <div className="flex items-center">
          <img src={PhoneIcon} alt="" className="w-5 h-5 mr-4" />
          <input
            type="text"
            placeholder=""
            name="number"
            className="input"
            maxLength={10}
            value={Props.formState.number}
            onChange={handleNumber}
            required
          />
        </div>
        {Props.isValidNumber ? (
          <></>
        ) : (
          <div className="flex items-center">
            <p className=" w-[85%] text-[var(--red)]  font-thin text-[.7rem] text-right ">
              Invalid Number
            </p>
          </div>
        )}
      </div>

      <div className="location mt-1">
        <label htmlFor="input" className="text !top-[0.6rem]">
          Location<sup className="text-[var(--red)] "> *</sup>
        </label>
        <div className="flex items-center">
          <img src={LocationIcon} alt="" className="w-5 h-5 mr-4" />
          <input
            type="text"
            placeholder=" City, State, Country "
            name="input"
            className="input"
            value={Props.formState.location}
            onChange={(e) => {
              Props.dispatch(updateForm({ location: e.target.value }));
            }}
          />
        </div>
      </div>

      {/* certificate */}
      <div className="certificate_div">
        <AddCertificate />
      </div>
    </div>
  );
}

export default FormHeaderLeft;
