import React, { useState, useEffect } from "react";
import Nav from "components/navbar/Nav";
import PhoneNavBar from "components/navbar/PhoneNavBar";
import PopUpLoginP from "components/signin/PhonePopUpLogin";
import { getFormData } from "api/CoachFormController";
import { getVideo } from "api/CoachFormController";
import { SubmitCoachForm } from "api/CoachFormController";
import EditFormYouTrain from "../coachFormComponents/EditFormYouTrain";
import EditPhoto from "../coachFormComponents/EditPhotos";
import EditVideo from "../coachFormComponents/EditVideo";
import { Link } from "react-router-dom";
import ErrorPopup from "components/popups/ErrorPopup";
import { ERROR_CONSTANTS } from "utility/constants/errorConstants";
import Aboutus from "pages/accountPage/accountPageComponents/AbooutUs";
import AddCertificate from "../coachFormComponents/AddCertificate";
import Certificate from "pages/accountPage/accountPageComponents/Certificate";
import { useDispatch, useSelector } from "react-redux";
import { CoachFormData, initialState } from "state/CoachFormData";
import { isDataEmpty } from "utility/utility_functions/isDataEmpty";
import { API_URL_CONSTANTS } from "utility/constants/apiUrlConstants";
import { getApis } from "hooks/api";

function EditCoachForm() {
  const dispatch = useDispatch();
  const Data = useSelector((state) => state.getCoachFromData);
  const [getData, setData] = useState([]);
  const [CoverImgSrc, setCoverImgSrc] = useState("");
  const [profileImgSrc, setProfileImgSrc] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  const [certificates, setCertificates] = useState([]);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [tagline, setTagline] = useState("");
  const [phone, setPhone] = useState("");
  const [gender, setGender] = useState("");
  const [location, setLocation] = useState("");
  const [about, setAbout] = useState("");
  const [run, setRun] = useState([]);
  const [swim, setSwim] = useState([]);
  const [bike, setBike] = useState([]);
  const [triathlon, setTriathlon] = useState([]);
  const [editClicked, setEditClicked] = useState(false);
  const [coverPhotoPath, setCoverPhotoPath] = useState(null);
  const [profilePhotoPath, setProfilePhotoPath] = useState(null);
  const [videoPath, setVideoPath] = useState(null);
  const [errorPopup, setErrorPopup] = useState(false); // error popup
  const [errorHeader, setErrorHeader] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isEdit, setEdit] = useState(false);
  const [editProfile, setEditProfile] = useState(false);
  const [editSetting, setEditSetting] = useState(false);
  const [editAbout, setEditAbout] = useState(false);
  const [editProfileDisable, setEditProfileDisable] = useState(false);
  const [showMore, setShowMore] = useState(false);

  // about us data
  const text = `${Data.about}`;

  const TAC = true;
  // scroll to top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  //API call get data for coach Form
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getFormData();

        dispatch(CoachFormData({ getCoachFromData: res.data }));

        setData(res.data);
        setCertificates(res.data.certifications || []);
        setGender(res.data.gender);
        setEmail(res.data.email || "");
        setName(res.data.name || "");
        setTagline(res.data.tagline || "");
        setPhone(res.data.phone || "");
        setLocation(res.data.location || "");
        setAbout(res.data.about || "");
        setRun(res.data.coaches.run || "");
        setSwim(res.data.coaches.swim || "");
        setBike(res.data.coaches.bike || "");
        setTriathlon(res.data.coaches.triathlon || "");

        if (res.data.cover_photo) {
          setCoverImgSrc(res.data.cover_photo);
        } else {
          const resCoverPhoto = await getApis(API_URL_CONSTANTS.GET_COVER_IMG);
          setCoverImgSrc(resCoverPhoto.data.message);
        }
        if (res.data.profile_photo) {
          setProfileImgSrc(res.data.profile_photo);
        } else {
          const resProfilePhoto = await getApis(
            API_URL_CONSTANTS.GET_PROFILE_IMG
          );
          setProfileImgSrc(resProfilePhoto.data.message);
        }
        if (res.data.video) {
          // const resGetVideo = await getVideo();

          setVideoUrl(res.data.video);
        }
      } catch (error) {
        return error;
      }
    };
    // if (isDataEmpty(Data, initialState)) {
    fetchData();
    //   console.log("api call");
    // } else {
    //   console.log("no Api call");
    // }
  }, []);

  async function handleSubmit() {
    if (
      name === "" ||
      tagline === "" ||
      phone === "" ||
      location === "" ||
      gender === "" ||
      about === ""
    ) {
      setErrorHeader("Error");
      setErrorMessage(
        "Fill up Name, Tagline, Phone Number, Location, Gender, About"
      );
      setErrorPopup(true);
    } else {
      try {
        const req = await SubmitCoachForm(
          name,
          tagline,
          email,
          phone,
          location,
          gender,
          about,
          run,
          swim,
          bike,
          triathlon,
          coverPhotoPath,
          profilePhotoPath,
          videoPath,
          certificates,
          TAC
        );

        if (req === true) {
          window.location.reload(true);
        } else {
          setErrorHeader(ERROR_CONSTANTS.NetworkError.Heading);
          setErrorMessage(ERROR_CONSTANTS.NetworkError.Message);
          setErrorPopup(true);
        }
      } catch {
        setErrorHeader(ERROR_CONSTANTS.NetworkError.Heading);
        setErrorMessage(ERROR_CONSTANTS.NetworkError.Message);
        setErrorPopup(true);
      }
    }
  }

  async function handleVideo(videoPath) {
    if (
      name === "" ||
      tagline === "" ||
      phone === "" ||
      location === "" ||
      gender === "" ||
      about === ""
    ) {
      setErrorHeader("Error");
      setErrorMessage(
        "Fill up Name, Tagline, Phone Number, Location, Gender, About"
      );
      setErrorPopup(true);
    } else {
      try {
        const req = await SubmitCoachForm(
          name,
          tagline,
          email,
          phone,
          location,
          gender,
          about,
          run,
          swim,
          bike,
          triathlon,
          coverPhotoPath,
          profilePhotoPath,
          videoPath,
          certificates,
          TAC
        );

        if (req === true) {
          window.location.reload(true);
        } else {
          setErrorHeader(ERROR_CONSTANTS.NetworkError.Heading);
          setErrorMessage(ERROR_CONSTANTS.NetworkError.Message);
          setErrorPopup(true);
        }
      } catch {
        setErrorHeader(ERROR_CONSTANTS.NetworkError.Heading);
        setErrorMessage(ERROR_CONSTANTS.NetworkError.Message);
        setErrorPopup(true);
      }
    }
  }

  const handleNumber = (e) => {
    const value = e.target.value;
    if (value.length <= 10 && /^\d*$/.test(value)) {
      setPhone(value);
    }
  };

  return (
    <>
      {errorPopup && (
        <>
          <div className="blur"></div>
          <ErrorPopup
            message={{
              heading: errorHeader,
              message: errorMessage,
            }}
            setErrorPopup={setErrorPopup}
          />
        </>
      )}
      {/* {getData ? <></> : <></>} */}
      <div className="coach_form">
        <Nav />
        <PhoneNavBar />
        <PopUpLoginP />
        {getData === undefined ? (
          <div className="flex justify-center items-center h-[100vh]">
            <h3>No Data</h3>
          </div>
        ) : (
          <>
            <div className="form_section">
              {/* Cover Photo */}

              <EditPhoto
                gender={gender}
                CoverImgSrc={CoverImgSrc}
                profileImgSrc={profileImgSrc}
                setCoverPhotoPath={setCoverPhotoPath}
                setProfilePhotoPath={setProfilePhotoPath}
              />

              {/* Personal detials */}

              <div className={`mt-16 profile_box ${isEdit ? "edit_mode" : ""}`}>
                <div className="profile_box_heading_div">
                  <h2>Personal Details</h2>
                  {isEdit ? (
                    <div className="flex">
                      <p
                        className="cancelButton"
                        onClick={() => {
                          setEdit(false);
                          setEditProfile(false);
                        }}
                      >
                        Cancel
                      </p>
                      <p className="saveButton" onClick={handleSubmit}>
                        Save
                      </p>
                    </div>
                  ) : (
                    <button
                      className="edit_button m-0 ml-[0.3rem]"
                      disabled={editProfileDisable}
                      onClick={() => {
                        setEdit(true);
                        setEditProfile(true);
                      }}
                    >
                      Edit
                    </button>
                  )}
                </div>
                <div className="input_info_box input_info_box_profile  input_info_edit">
                  <div className="first_half">
                    <p className="lable_tag">Name</p>
                    {isEdit ? (
                      <input
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    ) : (
                      <p className="lable_value ">{name}</p>
                    )}
                  </div>
                  <div className="second_half">
                    <p className="lable_tag">Tagline</p>
                    {isEdit ? (
                      <input
                        type="text"
                        value={tagline}
                        onChange={(e) => setTagline(e.target.value)}
                      />
                    ) : (
                      <p className="lable_value ">{tagline}</p>
                    )}
                  </div>
                </div>
                <div className="input_info_box input_info_box_profile  input_info_edit">
                  <div className="first_half">
                    <p className="lable_tag">Phone</p>
                    {isEdit ? (
                      <input
                        type="text"
                        value={phone}
                        maxLength={10}
                        onChange={handleNumber}
                      />
                    ) : (
                      <p className="lable_value ">{phone}</p>
                    )}
                  </div>
                  <div className="second_half">
                    <p className="lable_tag">Email</p>
                    <p className="lable_value ">
                      {" "}
                      {getData.email === undefined ? (
                        <span>&ndash;</span>
                      ) : (
                        `${getData.email}`
                      )}
                    </p>
                  </div>
                </div>
                <div className="input_info_box input_info_box_profile  input_info_edit">
                  <div className="first_half">
                    <p className="lable_tag">Location</p>
                    {isEdit ? (
                      <input
                        type="text"
                        value={location}
                        onChange={(e) => setLocation(e.target.value)}
                      />
                    ) : (
                      <p className="lable_value ">{location}</p>
                    )}
                  </div>
                  <div className="second_half">
                    <p className="lable_tag">Gender</p>
                    {isEdit ? (
                      <div className=" mydict_viewAccount_gender">
                        <div className="flex flex-wrap mt-2 justify-start">
                          <label>
                            <input
                              type="radio"
                              name="radio"
                              value={gender}
                              onChange={() => setGender("Male")}
                            />
                            <span
                              className={
                                gender === "Male" ? "genderColors" : ""
                              }
                            >
                              Male
                            </span>
                          </label>
                          <label>
                            <input
                              type="radio"
                              name="radio"
                              value={gender}
                              onChange={() => setGender("Female")}
                            />
                            <span
                              className={
                                gender === "Female" ? "genderColors" : ""
                              }
                            >
                              Female
                            </span>
                          </label>
                          <label>
                            <input
                              type="radio"
                              name="radio"
                              value={gender}
                              onChange={() => setGender("Trans")}
                            />
                            <span
                              className={
                                gender === "Trans" ? "genderColors" : ""
                              }
                            >
                              Don't want to say
                            </span>
                          </label>
                        </div>
                      </div>
                    ) : (
                      <p className="lable_value ">
                        {gender === undefined ? (
                          <span>&ndash;</span>
                        ) : (
                          `${gender}`
                        )}
                      </p>
                    )}
                  </div>
                </div>
              </div>
              {/* ABOUT US */}
              <div className={`setting_box ${isEdit ? "edit_mode" : ""}`}>
                <div className="profile_box_heading_div">
                  <h2>About</h2>
                  {isEdit ? (
                    <div className="flex">
                      <p
                        className="cancelButton"
                        onClick={() => {
                          setEdit(false);
                          setEditAbout(false);
                        }}
                      >
                        Cancel
                      </p>
                      <p className="saveButton" onClick={handleSubmit}>
                        Save
                      </p>
                    </div>
                  ) : (
                    <button
                      className="edit_button"
                      onClick={() => {
                        setEdit(true);
                        setEditAbout(true);
                      }}
                    >
                      Edit
                    </button>
                  )}
                </div>
                <div className="input_info_box">
                  <div className="about_box">
                    {isEdit ? (
                      <textarea
                        autoFocus
                        rows="3"
                        type="text"
                        className="break-words"
                        placeholder="Introduce yourself with a short writeup, for example; I'm Saurabh Shah, a versatile athlete with a background in running, cycling, and swimming."
                        value={about}
                        onChange={(e) => setAbout(e.target.value)}
                      />
                    ) : (
                      <>
                        {text === "" ? (
                          <></>
                        ) : (
                          <>
                            <p className="text-[var(--blue)] text-[1rem] break-words">
                              {text.length > 100 ? (
                                <>
                                  {" "}
                                  {showMore
                                    ? text
                                    : `${text.substring(0, 80)}...`}
                                  <span
                                    className="text-[var(--link)] cursor-pointer text-sm"
                                    onClick={() => {
                                      setShowMore(!showMore);
                                    }}
                                  >
                                    <br />
                                    {showMore ? " show less" : "show more"}
                                  </span>
                                </>
                              ) : (
                                <>{text}</>
                              )}
                            </p>
                          </>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>

              {/* <Aboutus
                setEditAbout={setEditAbout}
                editProfile={editProfile}
                editSetting={editSetting}
                about={about}
                setAbout={setAbout}
                handleSubmit={handleSubmit}
              /> */}
              <div className="setting_box">
                <div className="profile_box_heading_div">
                  <h2>You Train</h2>
                  <p onClick={() => setEditClicked(!editClicked)}>
                    {editClicked ? (
                      <>
                        <div className="flex">
                          <p
                            className="cancelButton"
                            onClick={() => {
                              setEdit(false);
                              setEditProfile(false);
                            }}
                          >
                            Cancel
                          </p>
                          <p className="saveButton" onClick={handleSubmit}>
                            Save
                          </p>
                        </div>{" "}
                      </>
                    ) : (
                      <div className="edit_button m-0 ml-[0.3rem]">Edit</div>
                    )}
                  </p>
                </div>
                <EditFormYouTrain
                  getData={getData}
                  run={run}
                  setRun={setRun}
                  swim={swim}
                  setSwim={setSwim}
                  bike={bike}
                  setBike={setBike}
                  triathlon={triathlon}
                  setTriathlon={setTriathlon}
                  editClicked={editClicked}
                />
              </div>

              {/* certificates */}

              <Certificate
                certificates={certificates}
                setCertificates={setCertificates}
                handleSubmit={handleSubmit}
              />

              {/* Video */}
              <EditVideo
                getData={getData}
                videoUrl={videoUrl}
                setVideoPath={setVideoPath}
                handleVideo={handleVideo}
              />

              <div className="view_form_back_btn">
                <Link to="/">&#8592; Back</Link>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default EditCoachForm;
