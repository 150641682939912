import React, { useContext, useEffect, useState, useRef } from "react";
import FormStep4 from "./FormStep4";
import RightArrow from "assets/icons/right-arrow.png";
import { useNavigate } from "react-router-dom";
import { SubmitCoachFormData } from "utility/constants/useContext";
import FormStep2 from "./FormStep2";
import BackArrow from "assets/icons/back.png";
import Nav from "components/navbar/Nav";
import PhoneNavBar from "components/navbar/PhoneNavBar";
import PopUpLoginP from "components/signin/PhonePopUpLogin";
import { updateForm } from "state/SetCoachFormData";

function FormStep3() {
  const Props = useContext(SubmitCoachFormData);

  const [validation, setValidation] = useState(false);
  const [nextClicked, setNextClicked] = useState(false);
  const [backClicked, setBackClicked] = useState(false);
  const [popper, setPopper] = useState(false);
  const navigate = useNavigate();

  // scroll to top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (Props.formState.isSwim === "yes" || Props.formState.isSwim === "no") {
      setValidation(true);
    } else {
      setValidation(false);
    }
  });

  const handleBackClick = () => {
    setBackClicked(true);
  };
  const handleCheckboxChange = (value) => {
    // If the value is already selected, remove it from the array
    const updatedSelectedDistancesRun =
      Props.formState.selectedDistancesSwim.includes(value)
        ? Props.formState.selectedDistancesSwim.filter((item) => item !== value)
        : [...Props.formState.selectedDistancesSwim, value];

    Props.dispatch(
      updateForm({ selectedDistancesSwim: updatedSelectedDistancesRun })
    );
  };

  return (
    <div>
      {backClicked ? (
        <FormStep2 />
      ) : (
        <>
          {nextClicked && validation ? (
            <FormStep4 />
          ) : (
            <div className="coach_form ">
              <Nav />
              <PhoneNavBar />
              <PopUpLoginP />
              <div className="form_section">
                <form action="" method="post" className="mt-16 m-0">
                  <div className="step2_div">
                    <div className="question_1">
                      <h4>Do you train athletes for Swimming ?</h4>
                      <div className="yes_no_div swim">
                        <label htmlFor="yes">
                          <input
                            type="radio"
                            name="radio"
                            id="yes"
                            onChange={() =>
                              Props.dispatch(updateForm({ isSwim: "yes" }))
                            }
                            checked={Props.formState.isSwim === "yes"}
                          />
                          <span>Yes</span>
                        </label>

                        <label htmlFor="no">
                          <input
                            type="radio"
                            name="radio"
                            id="no"
                            onChange={() => {
                              Props.dispatch(
                                updateForm({ selectedDistancesSwim: [] })
                              );
                              Props.dispatch(updateForm({ isSwim: "no" }));
                            }}
                            checked={Props.formState.isSwim === "no"}
                          />
                          <span>No</span>
                        </label>
                      </div>
                    </div>

                    {Props.formState.isSwim === "yes" ? (
                      <>
                        <div className="question_2">
                          <h4>Select distances</h4>
                          <div className="yes_no_div swim">
                            <label>
                              <input
                                type="checkbox"
                                name="radio"
                                value="1k"
                                onChange={() => handleCheckboxChange("1k")}
                                checked={Props.formState.selectedDistancesSwim.includes(
                                  "1k"
                                )}
                              />
                              <span>1k</span>
                            </label>

                            <label>
                              <input
                                type="checkbox"
                                name="radio"
                                id=""
                                value="1.5k"
                                onChange={() => handleCheckboxChange("1.5k")}
                                checked={Props.formState.selectedDistancesSwim.includes(
                                  "1.5k"
                                )}
                              />
                              <span>1.5k</span>
                            </label>

                            <label>
                              <input
                                type="checkbox"
                                name="radio"
                                id=""
                                value="1.9k"
                                onChange={() => handleCheckboxChange("1.9k")}
                                checked={Props.formState.selectedDistancesSwim.includes(
                                  "1.9k"
                                )}
                              />
                              <span>1.9k</span>
                            </label>

                            <label>
                              <input
                                type="checkbox"
                                name="radio"
                                id=""
                                value="3.8k"
                                onChange={() => handleCheckboxChange("3.8k")}
                                checked={Props.formState.selectedDistancesSwim.includes(
                                  "3.8k"
                                )}
                              />
                              <span>3.8k</span>
                            </label>

                            <label>
                              <input
                                type="checkbox"
                                name="radio"
                                id=""
                                value="5k"
                                onChange={() => handleCheckboxChange("5k")}
                                checked={Props.formState.selectedDistancesSwim.includes(
                                  "5k"
                                )}
                              />
                              <span>5k</span>
                            </label>
                          </div>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                    <div className="statusBar">
                      {popper && (
                        <div className="popper absolute bottom-12 right-1">
                          <p className=" text-[var(--red)] ">
                            fill up the fileds
                          </p>
                        </div>
                      )}

                      <p
                        className="next_btn statusBar"
                        onClick={() => {
                          if (validation === true) {
                            setNextClicked(true);
                            setPopper(false);
                          } else {
                            if (validation === false) {
                              setPopper(true);
                            }
                          }
                        }}
                      >
                        <div className="flex justify-between">
                          <div
                            className="mr-[10px] mt-[10px] cancelButton"
                            onClick={handleBackClick}
                          >
                            <div className=" flex items-center">
                              <img
                                src={BackArrow}
                                alt=""
                                className="next_img mr-1 ml-[-3px]"
                              />
                              Back
                            </div>
                          </div>
                          <div className="coach_cancel_Div flex">
                            <p
                              className="mr-[10px] mt-[10px] cancelButton"
                              onClick={() => {
                                navigate("/");
                              }}
                            >
                              Cancel
                            </p>
                            <div className="nextButton">
                              Next{" "}
                              <img
                                src={RightArrow}
                                alt=""
                                className="next_img mt-[6px]"
                              />
                            </div>
                          </div>
                        </div>
                      </p>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default FormStep3;
