import React, { useContext, useEffect, useMemo, useState } from "react";
import { Routes, Route } from "react-router-dom";
import { Home } from "pages/homePage/Home";
import { SwitchAccount } from "api/CoachFormController";
import { SetDataInlocalStorage } from "api/CoachFormController";
import { logout } from "api/Signin";
import Billing from "pages/bilingPage/BillingPage";
import CoachForm from "pages/coachForm/CoachForm";
import ViewAccount from "pages/accountPage/ViewAccount";
import ViewCoachForm from "pages/coachForm/viewCoachForm/ViewCoachForm";
import MyCoachingPlans from "pages/coachingPlans/MyCoachingPlans";
import EditCoachForm from "pages/coachForm/editCoachForm/EditCoachForm";
import { AppProps } from "utility/constants/useContext";
import { useDispatch, useSelector } from "react-redux";
import { UserData } from "state/ViewAccountState";
import "./mediaQuery.css";
import { getApis } from "hooks/api";
import { API_URL_CONSTANTS } from "utility/constants/apiUrlConstants";
import Athele from "pages/homePage/Athlete/Athlete";
import ViewCoaches from "pages/viewCoaches/ViewCoaches";
import Calender from "components/calender";
function App() {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.viewAccount);

  //check if clicked  SIGN UP button (sign up popup will come)
  const [isSignUp, setSignUp] = useState(false);

  // FORGET PASSWORD
  const [isForgotPassword, setForgotPassword] = useState(false);

  // coach form submit
  const [coachFormSubmit, setCoachFormSubmit] = useState(false);

  // useState for Laptop navbar open
  const [isOpen, setOpen] = useState(false);

  // useState for Phone navbar open
  const [isOpenP, setOpenP] = useState(false);

  // sign in popup for phone (signin slider)
  const [isSignInOpen, setSignInOpen] = useState(false);

  // Checking the token in local storage
  const [isSignedIn, setIsSignedIn] = useState(null);

  // user initial
  const [userInitial, setUserInitial] = useState("");

  // view account get data
  // const [getData, setData] = useState([]);

  // Switch account display or not
  const [isSwitchBtn, setSwitchBtn] = useState(false);

  // coach signup status => Pending verfication, Verified ect..
  const [coachSignUpStatus, setCoachSignupStatus] = useState("");

  //REMEMBER ME
  const [RememberMe, setRememberMe] = useState(false);

  //set refId
  const [refId, setRefId] = useState("");

  // check if user is signed in or not
  useEffect(() => {
    const checkSignedStatus = () => {
      const token =
        localStorage.getItem("token") ?? sessionStorage.getItem("token");

      if (token) {
        setIsSignedIn(true);
      } else {
        setIsSignedIn(false);
      }
    };

    checkSignedStatus();
  }, []);

  // user initial logic
  const userName =
    localStorage.getItem("name") ?? sessionStorage.getItem("name");
  useEffect(() => {
    if (userName === null) {
      return;
    } else {
      const ArryName = userName.split(" ");
      const filteredArray = ArryName.filter((str) => str !== "");

      if (filteredArray.length === 1) {
        setUserInitial(filteredArray[0].charAt(0).toUpperCase());
      } else if (filteredArray.length === 2) {
        setUserInitial(
          (
            filteredArray[0].charAt(0) + filteredArray[1].charAt(0)
          ).toUpperCase()
        );
      } else {
        setUserInitial(
          (
            filteredArray[0].charAt(0) + filteredArray.reverse()[0].charAt(0)
          ).toUpperCase()
        );
      }
    }
  }, [isOpen, isOpenP, isSignedIn, userName, data]);

  // logic for Switch account btn

  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        const res = await getApis(API_URL_CONSTANTS.GET_USER_DETAILS);
        dispatch(UserData({ viewAccountData: res.data }));

        if (res.data.profiles.length > 0) {
          // ref_id = res.data.profiles[0].ref;
          setRefId(res.data.profiles[0].ref);

          setSwitchBtn(true);
        } else {
          setSwitchBtn(false);
        }
      } catch (error) {}
    };
    fetchProfileData();
  }, [isSignedIn]);

  // API call for Switch account
  async function SwitchAccountBtn() {
    try {
      const res = await SwitchAccount(refId);
      if (res) {
        logout();
        SetDataInlocalStorage(res, RememberMe);

        setCoachSignupStatus("View Profile");
      }
    } catch (error) {}
  }

  // const AppPropsData = {
  //   isOpen,
  //   setOpen,
  //   isOpenP,
  //   setOpenP,
  //   isSignInOpen,
  //   setSignInOpen,
  //   isSignedIn,
  //   setIsSignedIn,
  //   userInitial,
  //   setUserInitial,
  //   isSwitchBtn,
  //   setSwitchBtn,
  //   coachSignUpStatus,
  //   setCoachSignupStatus,
  //   RememberMe,
  //   setRememberMe,
  //   SwitchAccountBtn,
  //   setCoachFormSubmit,
  //   coachFormSubmit,
  // };

  const AppPropsData = {
    // isSignUp,
    // setSignUp,
    // isForgotPassword,
    // setForgotPassword,
    isOpen,
    setOpen,
    isOpenP,
    setOpenP,
    isSignInOpen,
    setSignInOpen,
    isSignedIn,
    setIsSignedIn,
    userInitial,
    setUserInitial,
    isSwitchBtn,
    setSwitchBtn,
    coachSignUpStatus,
    setCoachSignupStatus,
    RememberMe,
    setRememberMe,
    SwitchAccountBtn,
    setCoachFormSubmit,
    coachFormSubmit,
  };

  // console.log("app. hit");
  // console.log(isOpen + " Is open 1");
  // console.log(isOpenP + " Is openP 2");
  // console.log(isSignedIn + " isSignedIn 3");
  // console.log(userInitial + " userInitial 4");
  // console.log(isSwitchBtn + " isSwitchBtn 5");
  // console.log(coachSignUpStatus + " coachSignUpStatus 6");
  // console.log(RememberMe + " RememberMe 7");
  // console.log(coachFormSubmit + " coachFormSubmit 8");
  return (
    <>
      <AppProps.Provider value={AppPropsData}>
        <Routes>
          <Route element={<Home />} path="/"></Route>

          <Route element={<Billing />} path="/mybilling"></Route>

          <Route element={<Athele />} path="/Athlete"></Route>

          <Route element={<ViewCoaches />} path="/viewCoaches"></Route>
          {isSignedIn && (
            <>
              <Route element={<ViewAccount />} path="/viewaccount"></Route>

              <Route element={<CoachForm />} path="/coachform"></Route>

              <Route element={<ViewCoachForm />} path="viewcoachform"></Route>

              <Route element={<EditCoachForm />} path="editcoachform"></Route>
              <Route element={<Calender/>} path="/calender"></Route>
              <Route
                element={<MyCoachingPlans />}
                path="/mycoachingplans"
              ></Route>
            </>
          )}
        </Routes>
      </AppProps.Provider>
    </>
  );
}

export default App;
