import axios from "axios";
import { APP_CONSTANTS } from "utility/constants/appConstants";
import { API_URL_CONSTANTS } from "utility/constants/apiUrlConstants.js";
import jwt_decode from "jwt-decode";

export function getToke() {
  const token = getJWTToken();
  return token;
}

export function Header() {
  const token = getJWTToken();
  if (token) {
    return {
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    };
  } else {
    return {
      "Content-type": "application/json",
    };
  }
}

//when you are calling a api then call it
export function getJWTToken() {
  const token =
    localStorage.getItem(APP_CONSTANTS.JWT_TOKEN) ??
    sessionStorage.getItem(APP_CONSTANTS.JWT_TOKEN);
  if (token === "null") {
    alert("Session Expired. Login again.");
    logout();
    return "";
  } else {
    const date = new Date();
    const currentTimeInSeconds = date.getSeconds();
    const decodedToken = jwt_decode(token);
    var expiryTimeInSeconds = decodedToken["exp"];
    const isValid = expiryTimeInSeconds > currentTimeInSeconds;
    if (isValid) {
      return token;
    } else {
      alert("Session Expired. Login again.");
      logout();
      return "";
    }
  }
}

export function logout() {
  localStorage.clear();
  sessionStorage.clear();
  window.location.href = "/";
}

export async function addPosts(email, password, RememberMe) {
  return axios
    .post(API_URL_CONSTANTS.REST_API_LOGIN_ENDPOINT, {
      email: email,
      password: password,
    })
    .then((response) => {
      if (response.status === 200 && response.data["token"] !== undefined) {
        const token = response.data["token"];
        const decodedToken = jwt_decode(token);
        var userEmail = decodedToken["email"];
        var userName = decodedToken["name"];
        var userRole = decodedToken["role"];
        var ref_id = decodedToken["id"];
        localStorage.setItem(APP_CONSTANTS.JWT_TOKEN, token);
        localStorage.setItem("email", userEmail);
        localStorage.setItem("name", userName.trim());
        localStorage.setItem("role", userRole);
        localStorage.setItem("ref_id", ref_id);
        localStorage.setItem("remember_me", RememberMe);
        return true;
      } else if (response.status === 200) {
        if (response.data.status_code === 2) {
          return response.data;
        }
      } else {
        throw new Error("Unexpected status code");
      }
    })
    .catch((error) => {
      throw error;
    });
}

export async function addPostsNoRememberMe(email, password) {
  return axios
    .post(API_URL_CONSTANTS.REST_API_LOGIN_ENDPOINT, {
      email: email,
      password: password,
    })
    .then((response) => {
      if (response.status === 200 && response.data["token"] !== undefined) {
        const token = response.data["token"];
        const decodedToken = jwt_decode(token);
        var userEmail = decodedToken["email"];
        var userName = decodedToken["name"];
        var userRole = decodedToken["role"];
        var ref_id = decodedToken["id"];
        sessionStorage.setItem(APP_CONSTANTS.JWT_TOKEN, token);
        sessionStorage.setItem("email", userEmail);
        sessionStorage.setItem("name", userName);
        sessionStorage.setItem("role", userRole);
        sessionStorage.setItem("ref_id", ref_id);
        return true;
      } else if (response.status === 200) {
        if (response.data.status_code === 2) {
          return response.data;
        }
      } else {
        throw new Error("Unexpected status code");
      }
    })
    .catch((error) => {
      throw error;
    });
}
