import React, { useContext, useEffect, useState } from "react";
import InformationPopup from "components/popups/InformationPopup";
import { SubmitCoachForm } from "api/CoachFormController";
import ErrorPopup from "components/popups/ErrorPopup";
import { ERROR_CONSTANTS } from "utility/constants/errorConstants";
import { AppProps, SubmitCoachFormData } from "utility/constants/useContext";
import { useNavigate } from "react-router-dom";
import SuccessPopup from "components/popups/SuccessPopup";
import parse from "html-react-parser";
import PhoneFormStep6 from "./PhoneFormStep6";
import Nav from "components/navbar/Nav";
import PhoneNavBar from "components/navbar/PhoneNavBar";
import PopUpLoginP from "components/signin/PhonePopUpLogin";
import BackArrow from "assets/icons/back.png";
import { updateForm } from "state/SetCoachFormData";

function PhoneFormStep7() {
  const Props = useContext(SubmitCoachFormData);
  const AppData = useContext(AppProps);

  const [isSignupDone, setIsSignupDone] = useState(false);
  const [popup, setPopup] = useState(false);
  const [isYes, setYes] = useState("");
  const [validation, setValidation] = useState(false);
  const [nextClicked, setNextClicked] = useState(false);
  const [TAC, setTAC] = useState(false);
  const [errorPopup, setErrorPopup] = useState(false);
  const [backClicked, setBackClicked] = useState(false);
  // error popup
  const [errorHeader, setErrorHeader] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedDistancesTriathlon, setSelectedDistancesTriathlon] = useState(
    []
  );
  const [disable, setDisable] = useState(false);
  const navigate = useNavigate();
  // scroll to top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (Props.formState.isTri === "" || TAC === false) {
      setDisable(true);
    } else {
      setDisable(false);
    }
    if (Props.formState.isTri === "yes" || Props.formState.isTri === "no") {
      setValidation(true);
    } else {
      setValidation(false);
    }
  }, [Props.formState.isTri, TAC]);
  const handleCheckboxChange = (value) => {
    // If the value is already selected, remove it from the array
    const updatedSelectedDistancesRun =
      Props.formState.selectedDistancesTriathlon.includes(value)
        ? Props.formState.selectedDistancesTriathlon.filter(
            (item) => item !== value
          )
        : [...Props.formState.selectedDistancesTriathlon, value];

    Props.dispatch(
      updateForm({ selectedDistancesTriathlon: updatedSelectedDistancesRun })
    );
  };
  let about = parse(Props.formState.aboutYourSelf);

  const handleBackClick = () => {
    setBackClicked(true);
  };

  async function handleSubmit(e) {
    e.preventDefault();
    const req = await SubmitCoachForm(
      Props.formState.name,
      Props.formState.tagline,
      Props.email,
      Props.formState.number,
      Props.formState.location,
      Props.gender,
      about.props.children,
      Props.formState.selectedDistancesRun,
      Props.formState.selectedDistancesSwim,
      Props.formState.selectedDistancesCycle,
      Props.formState.selectedDistancesTriathlon,
      Props.formState.coverPhotoPath,
      Props.formState.profilePhotoPath,
      Props.formState.videoPath,
      Props.formState.certificates,
      TAC
    );

    if (req === true) {
      AppData.setCoachFormSubmit(true);
      navigate("/");
    } else {
      setErrorHeader(ERROR_CONSTANTS.NetworkError.Heading);
      setErrorMessage(ERROR_CONSTANTS.NetworkError.Message);
      setErrorPopup(true);
    }
  }
  return (
    <div>
      {backClicked ? (
        <PhoneFormStep6 />
      ) : (
        <>
          {nextClicked && validation ? (
            <></>
          ) : (
            <>
              {errorPopup && (
                <>
                  <div className="blur"></div>
                  <ErrorPopup
                    message={{
                      heading: errorHeader,
                      message: errorMessage,
                    }}
                    setErrorPopup={setErrorPopup}
                  />
                </>
              )}
              {popup && (
                <>
                  <div className="delete_blur"></div>
                  <InformationPopup
                    message={{
                      heading: "Coach Request Form Submitted",
                      message:
                        "  Your Form will get verified in 5 to 6 working day’s Keep Checking your profile",
                    }}
                  />
                </>
              )}
              <SuccessPopup
                setIsSignupDone={setIsSignupDone}
                isSignupDone={isSignupDone}
                message={{
                  heading: "Coach Request Is Submitted successfully!",
                  message:
                    "your request will get verified in 5-6 working days.",
                }}
              />

              <div className="coach_form">
                <Nav />
                <PhoneNavBar />
                <PopUpLoginP />
                <div className="form_section">
                  <form action="" method="post" className="mt-16 m-0">
                    <div className="phone_form_step7 mt-16">
                      <div className="step2_div">
                        <div className="question_1 ">
                          <h4>Do you train athletes for Triathlon ?</h4>
                          <div className="yes_no_div swim">
                            <label>
                              <input
                                type="radio"
                                name="radio"
                                onChange={() => {
                                  Props.dispatch(updateForm({ isTri: "yes" }));
                                }}
                                checked={Props.formState.isTri === "yes"}
                              />
                              <span>Yes</span>
                            </label>

                            <label>
                              <input
                                type="radio"
                                name="radio"
                                onChange={() => {
                                  Props.dispatch(
                                    updateForm({
                                      selectedDistancesTriathlon: [],
                                    })
                                  );
                                  Props.dispatch(updateForm({ isTri: "no" }));
                                }}
                              />
                              <span>No</span>
                            </label>
                          </div>
                        </div>

                        {Props.formState.isTri === "yes" ? (
                          <>
                            <div className="question_2">
                              <h4>Select distances</h4>
                              <div className="yes_no_div swim">
                                <label>
                                  <input
                                    type="checkbox"
                                    name="radio"
                                    onChange={() =>
                                      handleCheckboxChange("Sprint Tri")
                                    }
                                    checked={Props.formState.selectedDistancesTriathlon.includes(
                                      "Sprint Tri"
                                    )}
                                  />
                                  <span>Sprint Tri</span>
                                </label>

                                <label>
                                  <input
                                    type="checkbox"
                                    name="radio"
                                    id=""
                                    onChange={() =>
                                      handleCheckboxChange("5150 ironman")
                                    }
                                    checked={Props.formState.selectedDistancesTriathlon.includes(
                                      "5150 ironman"
                                    )}
                                  />
                                  <span>5150 ironman</span>
                                </label>

                                <label>
                                  <input
                                    type="checkbox"
                                    name="radio"
                                    id=""
                                    onChange={() =>
                                      handleCheckboxChange("Ironman 70.3")
                                    }
                                    checked={Props.formState.selectedDistancesTriathlon.includes(
                                      "Ironman 70.3"
                                    )}
                                  />
                                  <span>Ironman 70.3</span>
                                </label>

                                <label>
                                  <input
                                    type="checkbox"
                                    name="radio"
                                    id=""
                                    onChange={() =>
                                      handleCheckboxChange("Ironman")
                                    }
                                    checked={Props.formState.selectedDistancesTriathlon.includes(
                                      "Ironman"
                                    )}
                                  />
                                  <span>Ironman</span>
                                </label>
                              </div>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                        <div className="text-center mt-2">
                          <input
                            type="checkbox"
                            id="terms"
                            value="TAC"
                            onChange={() => setTAC(!TAC)}
                            className="border-black"
                          />
                          <label htmlFor="terms">
                            I agree to the Terms of use,{" "}
                            <a href="https://www.trackofit.com/privacypolicy.html">
                              Privacy Policy{" "}
                            </a>
                            and <a href="">Refund Policy</a>
                          </label>
                        </div>
                        <div className="flex justify-between statusBarPhone">
                          <p
                            className="mr-[10px] mt-[10px] cancelButton"
                            onClick={handleBackClick}
                          >
                            <div className=" flex items-center">
                              <img
                                src={BackArrow}
                                alt=""
                                className="next_img mr-1 ml-[-3px]"
                              />
                              Back
                            </div>
                          </p>
                          <div className="coach_cancel_Div flex ">
                            <p
                              className="mr-[10px] mt-[10px] cancelButton"
                              onClick={() => {
                                navigate("/");
                              }}
                            >
                              Cancel
                            </p>
                            <button
                              className=" nextButton"
                              type="submit"
                              onClick={handleSubmit}
                              disabled={disable}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                        {/* <div className="Coach_from_submit_div">
                <button
                  className="Coach_from_submit"
                  type="submit"
                  onClick={handleSubmit}
                  disabled={disable}
                >
                  SUBMIT
                </button>
              </div> */}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
}

export default PhoneFormStep7;
