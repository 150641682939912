import React, { useContext, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateCertificates } from "state/SetCoachFormData";
import AddIcon from "assets/icons/add.svg";
import cancels from "assets/img/cancels.png";
import Ribbin from "assets/img/ribbon.png";
import redCancel from "assets/img/redCancel.png";
import { SubmitCoachFormData } from "utility/constants/useContext";

function AddCertificate() {
  const Props = useContext(SubmitCoachFormData);
  const dispatch = useDispatch();
  const certificates = useSelector(
    (state) => state.setCoachFromData.certificates
  );
  const today = new Date().toISOString().split("T")[0];

  // Handler to add a new certificate
  const handleAddCertificate = () => {
    dispatch(
      updateCertificates([
        ...certificates,
        { name: "", issued_by: "", issued_on: "" },
      ])
    );
    Props.setErrors([
      ...Props.errors,
      { nameError: "", issuedByError: "", issuedOnError: "" },
    ]);
  };

  // Handler to remove a certificate
  const handleRemoveCertificate = (index) => {
    const updatedCertificates = certificates.filter((_, i) => i !== index);
    const updatedErrors = Props.errors.filter((_, i) => i !== index);
    dispatch(updateCertificates(updatedCertificates));
    Props.setErrors(updatedErrors);
  };

  // Handler to update certificate input fields
  const handleChange = (e, index, key) => {
    const updatedCertificates = [...certificates];
    updatedCertificates[index] = {
      ...updatedCertificates[index],
      [key]: e.target.value,
    };
    dispatch(updateCertificates(updatedCertificates));

    // Reset error messages if the field is valid
    const updatedErrors = [...Props.errors];
    if (key === "name" && e.target.value) {
      updatedErrors[index] = { ...updatedErrors[index], nameError: "" };
    } else if (key === "issued_by" && e.target.value) {
      updatedErrors[index] = { ...updatedErrors[index], issuedByError: "" };
    }
    Props.setErrors(updatedErrors);
  };

  // Handler to update date input and format it
  const handleDate = (e, index) => {
    const date = e.target.value;
    const updatedCertificates = [...certificates];
    updatedCertificates[index] = {
      ...updatedCertificates[index],
      issued_on: date ? date.split("-").reverse().join("-") : "",
    };
    dispatch(updateCertificates(updatedCertificates));

    // Reset error messages if the date is valid
    const updatedErrors = [...Props.errors];
    if (date) {
      updatedErrors[index] = { ...updatedErrors[index], issuedOnError: "" };
    }
    Props.setErrors(updatedErrors);
  };

  return (
    <div className="add_certificate_section mt-8">
      <h4>Add your certificates that you have earned</h4>
      <div>
        {certificates.map((certificate, index) => (
          <div
            className="flex flex-col -ml-2 pr-[0.7rem] text-[1rem] border-dotted border-t border-[#023047b3] bg-white text-[--edit-blue] font-normal w-[90%]"
            key={index}
          >
            <div className="flex">
              <div className="relative inline-block mt-8">
                <img src={Ribbin} alt="" className="w-10 h-10" />
                <span className="absolute top-1.5 right-4 text-black font-bold text-sm">
                  {index + 1}
                </span>
              </div>
              <div className="ml-4 w-full">
                <p
                  className="mb-4 mt-[-20px] bg-white w-8 pl-1 ml-auto cursor-pointer"
                  onClick={() => handleRemoveCertificate(index)}
                >
                  <div className="relative w-6 h-6">
                    <img src={cancels} alt="cancel" className="w-6 h-6 mt-2" />
                    <img
                      src={redCancel}
                      alt="cancel hover"
                      className="w-6 h-6 absolute top-0 left-0 opacity-0 duration-300 hover:opacity-100"
                    />
                  </div>
                </p>
                <div className="certificate_of flex flex-col -mt-6">
                  <label className="certificate_of relative top-[1.2rem] py-0 px-1 left-[3%] w-fit bg-white">
                    Certificate Of
                    <sup className="text-[var(--red)] !top-[-0.6rem] relative">
                      *
                    </sup>
                  </label>
                  <input
                    type="text"
                    className="mt-2"
                    value={certificate.name}
                    onChange={(e) => handleChange(e, index, "name")}
                  />
                  <span className="text-[var(--red)] text-[.8rem] mb-2 text-right">
                    {Props.errors[index]?.nameError}
                  </span>
                </div>

                <div className="issued_by flex flex-col mt-[-12px]">
                  <label className="issued_by relative top-[0.8rem] py-0 px-1 left-[3%] w-fit bg-white">
                    Issued By
                    <sup className="text-[var(--red)] !top-[-0.5rem] relative">
                      *
                    </sup>
                  </label>
                  <input
                    type="text"
                    value={certificate.issued_by}
                    onChange={(e) => handleChange(e, index, "issued_by")}
                  />
                  <span className="text-[var(--red)] text-[.8rem] mb-2 text-right">
                    {Props.errors[index]?.issuedByError}
                  </span>
                </div>

                <div className="issued_on flex flex-col mt-[-12px]">
                  <label className="issued_on relative top-[0.7rem] py-0 px-1 left-[3%] w-fit bg-white">
                    Issued On
                    <sup className="text-[var(--red)] !top-[-0.5rem] relative">
                      *
                    </sup>
                  </label>
                  <input
                    max={today}
                    type="date"
                    value={certificate.issued_on.split("-").reverse().join("-")}
                    className="date-picker"
                    onChange={(e) => handleDate(e, index)}
                  />
                  <span className="text-[var(--red)] text-[.8rem] mb-2 text-right">
                    {Props.errors[index]?.issuedOnError}
                  </span>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="add_div">
        <p className="add_btn" onClick={handleAddCertificate}>
          Add Certificates <img src={AddIcon} alt="Add Icon" />
        </p>
      </div>
    </div>
  );
}

export default AddCertificate;
