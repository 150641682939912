import React, { useContext, useEffect, useState } from "react";
import FormStep5 from "./FormStep5";
import RightArrow from "assets/icons/right-arrow.png";
import { useNavigate } from "react-router-dom";
import { SubmitCoachFormData } from "utility/constants/useContext";
import BackArrow from "assets/icons/back.png";
import Nav from "components/navbar/Nav";
import PhoneNavBar from "components/navbar/PhoneNavBar";
import PopUpLoginP from "components/signin/PhonePopUpLogin";
import { updateForm } from "state/SetCoachFormData";

import FormStep3 from "./FormStep3";
function FormStep4() {
  const Props = useContext(SubmitCoachFormData);

  const [validation, setValidation] = useState(false);
  const [nextClicked, setNextClicked] = useState(false);
  const [backClicked, setBackClicked] = useState(false);
  const [popper, setPopper] = useState(false);
  const navigate = useNavigate();

  // scroll to top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleBackClick = () => {
    setBackClicked(true);
  };

  useEffect(() => {
    if (Props.formState.isCycle === "yes" || Props.formState.isCycle === "no") {
      setValidation(true);
    } else {
      setValidation(false);
    }
  });
  const handleCheckboxChange = (value) => {
    // If the value is already selected, remove it from the array
    const updatedSelectedDistancesRun =
      Props.formState.selectedDistancesCycle.includes(value)
        ? Props.formState.selectedDistancesCycle.filter(
            (item) => item !== value
          )
        : [...Props.formState.selectedDistancesCycle, value];

    Props.dispatch(
      updateForm({ selectedDistancesCycle: updatedSelectedDistancesRun })
    );
  };

  return (
    <div>
      {backClicked ? (
        <FormStep3 />
      ) : (
        <>
          {nextClicked && validation ? (
            <FormStep5 />
          ) : (
            <div className="coach_form">
              <Nav />
              <PhoneNavBar />
              <PopUpLoginP />
              <div className="form_section">
                <form action="" method="post" className="mt-16 m-0">
                  <div className="step2_div">
                    <div className="question_1 ">
                      <h4>Do you train athletes for Cycling ?</h4>
                      <div className="yes_no_div swim">
                        <label htmlFor="yes">
                          <input
                            type="radio"
                            name="radio"
                            id="yes"
                            onChange={() =>
                              Props.dispatch(updateForm({ isCycle: "yes" }))
                            }
                            checked={Props.formState.isCycle === "yes"}
                          />
                          <span>Yes</span>
                        </label>

                        <label htmlFor="no">
                          <input
                            type="radio"
                            name="radio"
                            id="no"
                            onChange={() => {
                              Props.dispatch(
                                updateForm({ selectedDistancesCycle: [] })
                              );
                              Props.dispatch(updateForm({ isCycle: "no" }));
                            }}
                            checked={Props.formState.isCycle === "no"}
                          />
                          <span>No</span>
                        </label>
                      </div>
                    </div>

                    {Props.formState.isCycle === "yes" ? (
                      <>
                        <div className="question_2">
                          <h4>Select distances</h4>
                          <div className="yes_no_div swim pb-[6rem]">
                            <label>
                              <input
                                type="checkbox"
                                name="radio"
                                value="40k"
                                onChange={() => handleCheckboxChange("40k")}
                                checked={Props.formState.selectedDistancesCycle.includes(
                                  "40k"
                                )}
                              />
                              <span>40k</span>
                            </label>

                            <label>
                              <input
                                type="checkbox"
                                name="radio"
                                id=""
                                value="90k"
                                onChange={() => handleCheckboxChange("90k")}
                                checked={Props.formState.selectedDistancesCycle.includes(
                                  "90k"
                                )}
                              />
                              <span>90k</span>
                            </label>

                            <label>
                              <input
                                type="checkbox"
                                name="radio"
                                id=""
                                value="100k"
                                onChange={() => handleCheckboxChange("100k")}
                                checked={Props.formState.selectedDistancesCycle.includes(
                                  "100k"
                                )}
                              />
                              <span>100k</span>
                            </label>

                            <label>
                              <input
                                type="checkbox"
                                name="radio"
                                id=""
                                value="160k"
                                onChange={() => handleCheckboxChange("160k")}
                                checked={Props.formState.selectedDistancesCycle.includes(
                                  "160k"
                                )}
                              />
                              <span>160k</span>
                            </label>

                            <label>
                              <input
                                type="checkbox"
                                name="radio"
                                id=""
                                value="200k"
                                onChange={() => handleCheckboxChange("200k")}
                                checked={Props.formState.selectedDistancesCycle.includes(
                                  "200k"
                                )}
                              />
                              <span>200k</span>
                            </label>

                            <label>
                              <input
                                type="checkbox"
                                name="radio"
                                id=""
                                value="Time Trail"
                                onChange={() =>
                                  handleCheckboxChange("Time Trail")
                                }
                                checked={Props.formState.selectedDistancesCycle.includes(
                                  "Time Trail"
                                )}
                              />
                              <span>Time Trail</span>
                            </label>
                          </div>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                    <div className="statusBar">
                      {popper && (
                        <div className="popper absolute bottom-12 right-1">
                          <p className="text-[var(--red)]">
                            fill up the fileds
                          </p>
                        </div>
                      )}

                      <p
                        className="next_btn statusBar"
                        onClick={() => {
                          if (validation === true) {
                            setNextClicked(true);
                            setPopper(false);
                          } else {
                            if (validation === false) {
                              setPopper(true);
                            }
                          }
                        }}
                      >
                        <div className="flex justify-between">
                          <div
                            className="mr-[10px] mt-[10px] cancelButton"
                            onClick={handleBackClick}
                          >
                            <div className=" flex items-center">
                              <img
                                src={BackArrow}
                                alt=""
                                className="next_img mr-1 ml-[-3px]"
                              />
                              Back
                            </div>
                          </div>
                          <div className="coach_cancel_Div flex">
                            <p
                              className="mr-[10px] mt-[10px] cancelButton"
                              onClick={() => {
                                navigate("/");
                              }}
                            >
                              Cancel
                            </p>
                            <div className="nextButton">
                              Next{" "}
                              <img
                                src={RightArrow}
                                alt=""
                                className="next_img mt-[6px]"
                              />
                            </div>
                          </div>
                        </div>
                      </p>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default FormStep4;
