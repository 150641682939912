import React, { useState, useEffect } from "react";
import CoachingPlanCard from "./coachingPlansComponents/CoachingPlanCard";
import AddPopup from "./coachingPlansComponents/AddPopup";
import Nav from "components/navbar/Nav";
import PhoneNavBar from "components/navbar/PhoneNavBar";
import PopUpLoginP from "components/signin/PhonePopUpLogin";
import AddBtn from "assets/icons/add_btn_card.svg";
import "./coaching-plan.css";

function MyCoachingPlans(props) {
  const {
    isOpen,
    setOpen,
    isOpenP,
    setOpenP,
    isSignedIn,
    userInitial,
    setIsSignedIn,
    isSwitchBtn,
    SwitchAccountBtn,
    isSignInOpen,
    setSignInOpen,
  } = props;

  const [add, setAdd] = useState(false);
  const [edit, setEdit] = useState(false);
  const [planId, setPlanId] = useState("");
  const [name, setName] = useState("");
  const [planDetails, setPlanDetails] = useState("");
  const [planCharge, setPlanCharge] = useState("");

  // scroll to top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function handleEdit(plan_id, plan_name, description, plan_charge) {
    setAdd(true);
    setEdit(true);
    setPlanId(plan_id);
    setName(plan_name);
    setPlanDetails(description);
    setPlanCharge(plan_charge);
  }

  function handleAdd() {
    setAdd(true);
    setPlanId("");
    setName("");

    setPlanDetails("");
    setPlanCharge("");
  }

  function handleRevomeId(plan_id) {
    setPlanId(plan_id);
  }

  return (
    <div className="bg-[var(--light-grey)]">
      <Nav
        isOpen={isOpen}
        setOpen={setOpen}
        userInitial={userInitial}
        isSignedIn={isSignedIn}
        isSwitchBtn={isSwitchBtn}
        SwitchAccountBtn={SwitchAccountBtn}
      />
      <PhoneNavBar
        isOpenP={isOpenP}
        setOpenP={setOpenP}
        isSignedIn={isSignedIn}
        userInitial={userInitial}
      />
      <PopUpLoginP
        isOpenP={isOpenP}
        setOpenP={setOpenP}
        setIsSignedIn={setIsSignedIn}
        isSignedIn={isSignedIn}
        userInitial={userInitial}
        isSignInOpen={isSignInOpen}
        setSignInOpen={setSignInOpen}
      />
      <div className="my_coaching_plan_section min-h-[100vh] bg-[#f6f6f6] w-[75%] mx-auto">
        {add && (
          <>
            <div className="blur"></div>
            <AddPopup
              setAdd={setAdd}
              edit={edit}
              setEdit={setEdit}
              planId={planId}
              name={name}
              setName={setName}
              planDetails={planDetails}
              setPlanDetails={setPlanDetails}
              planCharge={planCharge}
              setPlanCharge={setPlanCharge}
            />
          </>
        )}

        <div className="center_90_width">
          <div className=" flex flex-col justify-center items-center pt-[5rem]">
            <h2 className=" font-normal text-[var(--blue)] text-[2.5rem]">
              Add Your Perfect Plan
            </h2>
            <p className="text-center text-[var(--light-blue)] text-[1.2rem] font-light pt-2">
              Here is your complimentary plan, available to just 5 users. You
              can add your plans here, which will then be displayed to the
              athletes.
            </p>
          </div>

          <div className="card_div pt-[1rem] flex justify-start flex-wrap">
            <CoachingPlanCard
              handleEdit={handleEdit}
              add={add}
              handleRevomeId={handleRevomeId}
              planId={planId}
            />
            <div className="add_card_plan ">
              <div className="add_btn" onClick={handleAdd}>
                <img src={AddBtn} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default MyCoachingPlans;
